import List from '@material-ui/core/List'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import {
  padding,
  scale,
  scrollOffset,
  scrollWithOffset
} from '../../layout/Menu'
import { HashLink as Link } from 'react-router-hash-link'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    '&:hover': { background: 'inherit' } // disable circle around checkbox when focussed
  }
}))

/**
 * @param {Object} props
 * @param {string} props.text
 * @param {string} props.anchorName
 * @param {boolean} props.checked
 * @param {(arg: string) => void} props.toggle
 * @returns {JSX.Element}
 */
export const MenuItem = ({ text, anchorName, checked, toggle }) => {
  const classes = useStyles()

  return (
    <ListItem
      style={{ transform: `scale(${scale})`, padding: padding }}
      button
      key={text}
      onClick={() => toggle(text)}
    >
      <ListItemIcon>
        <Checkbox
          disableRipple
          checked={checked}
          className={classes.root}
          focusRipple={false}
          size="small"
          color="default"
          // onChange={() => toggle(text)}
          // onMouseDown={(event) => event.stopPropagation()}
        />
      </ListItemIcon>
      <Link
        style={{ textDecoration: 'none', color: 'black' }}
        smooth
        to={`#${anchorName}`}
        scroll={(el) => scrollWithOffset(el, scrollOffset)}
      >
        <ListItemText
          primaryTypographyProps={{ variant: 'body2' }}
          primary={text}
        />
      </Link>
    </ListItem>
  )
}

const BackOfficePageMenu = ({ statuses, toggle }) => {
  return statuses ? (
    <List>
      {statuses
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .map((status) => (
          <MenuItem
            key={status.id}
            text={status.name}
            anchorName={status.id}
            toggle={toggle}
            checked={status.isVisible}
          />
        ))}
    </List>
  ) : (
    <LoadingIndicator />
  )
}

export default BackOfficePageMenu
