import React from 'react'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Line,
  LineChart,
  Brush
} from 'recharts'
import CustomizedAxisTick from './AxisTick'
import { getMinMax } from '../StatisticsPageContent'

const StatsLineChart = ({ data }) => {
  const [min, max] = getMinMax(data)

  return (
    <div style={{ width: '100%', height: 400 }}>
      <ResponsiveContainer>
        <LineChart
          data={data.data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" height={70} tick={<CustomizedAxisTick />} />
          <YAxis domain={[min, max]} />
          <Tooltip />
          <Legend />
          <Brush {...data.brush} dataKey="name" height={30} stroke="#000" />
          {data?.bars &&
            data.bars.map((el) => (
              <Line
                type="monotone"
                key={el.name}
                dataKey={`${el.name}`}
                stroke={`${el.color}`}
              />
            ))}
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default StatsLineChart
