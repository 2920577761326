import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import { useState } from 'react'
import { useField } from 'react-final-form'
import { client } from '../../client'
import { useAsyncData } from '../../utils/useAsyncData'
import { LoadingIndicator } from '../LoadingIndicator'

const getFullName = (psychologist: any) => {
  if (!psychologist.user) {
    return ''
  }
  const { firstName, infix, lastName } = psychologist.user
  return [firstName, infix, lastName].filter(Boolean).join(' ')
}

type PsychologistSelectorProps = {
  label: string
  name: string
  style?: React.CSSProperties
}

const psychologistSortFn = (a, b) => {
  if (!a.user) {
    return -1
  }
  if (!b.user) {
    return 1
  }
  return getFullName(a).localeCompare(getFullName(b))
}

const PsychologistSelector = ({
  label,
  name,
  style
}: PsychologistSelectorProps): JSX.Element => {
  // TODO: this should be a prop
  const {
    input: { value: isLegacy }
  } = useField('isLegacy')
  const [options, loading] = useAsyncData<any>(async () =>
    client.getPsychologistOptions(!isLegacy)
  )
  const field = useField(name)
  const [initialId] = useState(field.input.value)

  if (loading) return <LoadingIndicator />

  const sortedOptions = options
    .filter((o: any) => !!o.user)
    .toSorted(psychologistSortFn)

  return (
    <Autocomplete
      style={style}
      options={sortedOptions}
      getOptionLabel={getFullName}
      onChange={(_, value) => field.input.onChange(value?.id)}
      defaultValue={options.find((p: any) => p.id === initialId)}
      renderInput={(params) => <TextField {...params} label={label} />}
    />
  )
}

export default PsychologistSelector
