import { FieldArray } from 'react-final-form-arrays'
import Button from '@material-ui/core/Button'
import FormRow from '../../FormRow'
import { PlusCircle } from 'mdi-material-ui'
import { client } from '../../../../client'
import { useField, useForm } from 'react-final-form'
import { dateTimeToDate } from '../../../../utils/dates'
import FormSection from '../../FormSection'
import NotesSection from '../NotesSection/NotesSection'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import Alert from '@material-ui/lab/Alert'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const EditDateDialog = ({ date, open, onClose, onChange }) => {
  const { t } = useTranslation('sessionNotesSection')
  const [selectedDate, handleDateChange] = useState(date)

  const handleClose = () => {
    handleDateChange(date)
    onClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{t('changeDate')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Alert severity="info">{t('alertInfo')}</Alert>
        </DialogContentText>
        <KeyboardDatePicker
          value={selectedDate}
          onChange={(date) => handleDateChange(date)}
          format="dd-MM-yyyy"
          invalidDateMessage={t('invalidDateMessage')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          {t('cancel')}
        </Button>
        <Button
          onClick={() => {
            onChange(selectedDate)
            onClose()
          }}
          color="primary"
          autoFocus
        >
          {t('confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const SessionNotesSection = () => {
  const { t } = useTranslation('sessionNotesSection')

  const {
    input: { value: programId }
  } = useField('id')

  const form = useForm()

  const [open, setOpen] = useState(-1)

  const handleAddSessionNote = async () => {
    return await client.addNote(programId)
  }

  const handleRemoveSessionNote = async (note) => {
    await client.removeNote(note.id)
  }

  const handleChangeDate = async (note) => {
    await client.updateNote(note.id, note)
    const sessionNotes = await client.findNotesByTreatmentProgramId(programId)
    form.mutators.setValue('sessionNotes', sessionNotes)
  }

  return (
    <FieldArray name="sessionNotes">
      {({ fields }) => {
        return (
          <>
            {fields.map((name, index) => (
              <FormSection
                key={fields.value[index].id}
                anchor={`session${index + 1}`}
                title={
                  <div>
                    {`${t('notes')} `}{' '}
                    {dateTimeToDate(fields.value[index].createdAt)}{' '}
                    <EditIcon
                      style={{ fontSize: 14 }}
                      onClick={() => setOpen(index)}
                    />
                    <EditDateDialog
                      date={new Date(fields.value[index].createdAt)}
                      open={open === index}
                      onClose={() => setOpen(-1)}
                      onChange={async (date) => {
                        const note = {
                          ...fields.value[index],
                          createdAt: date.toISOString()
                        }
                        await handleChangeDate(note)
                      }}
                    />
                  </div>
                }
                name={`${name}.text`}
                component={NotesSection}
                action={
                  <IconButton
                    onClick={() =>
                      handleRemoveSessionNote(fields.remove(index))
                    }
                  >
                    <DeleteIcon color="disabled" fontSize="small" />
                  </IconButton>
                }
              />
            ))}
            <FormRow>
              <Button
                fullWidth
                type="button"
                color="primary"
                onClick={async () => {
                  const note = await handleAddSessionNote()
                  fields.push(note)
                }}
                style={{ marginTop: -20, marginBottom: 20 }}
              >
                <PlusCircle />
                {t('addSession')}
              </Button>
            </FormRow>
          </>
        )
      }}
    </FieldArray>
  )
}

export default SessionNotesSection
