const indicatorDict: Record<string, string> = {
  Slaapmakend: 'SLM',
  'Mulder & Van Schie': 'MVS'
}

export default function PracticeIndicator({
  practiceName
}: {
  practiceName: string
}): JSX.Element {
  const indicator = indicatorDict[practiceName] || ''
  return <span>{indicator}</span>
}
