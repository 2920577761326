import { Children } from 'react'
import Grid, { GridSize } from '@material-ui/core/Grid'

type Props = {
  xs?: GridSize | GridSize[]
  children?: React.ReactNode
}
const FormRow = ({ xs, children }: Props): JSX.Element => (
  <Grid container spacing={2}>
    {Children.map(children, (child, index) => {
      const nchildren = Children.count(children)
      return (
        <Grid item xs={xs === undefined ? 12 / nchildren : xs[index]}>
          {child}
        </Grid>
      )
    })}
  </Grid>
)

export default FormRow
