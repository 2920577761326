import { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'

const ValidationText = ({ text, onChange }) => {
  const { t } = useTranslation('alertDialogComponent')

  const [current, setCurrent] = useState('')

  const handleChange = (e) => {
    const entered = e.target.value
    setCurrent(entered)
    onChange(entered === text)
  }

  const confirmed = current === text

  return (
    <TextField
      required
      autoFocus
      color="secondary"
      error={!confirmed}
      onChange={handleChange}
      helperText={
        !confirmed && (
          <span>
            Type <b>{text}</b> {t('toConfirm')}
          </span>
        )
      }
    />
  )
}

const AlertDialog = ({
  title,
  text,
  content,
  render,
  confirmText,
  cancelText,
  onConfirm,
  validationText,
  initialState = false,
  disableSubmit = false,
  ...rest
}) => {
  const [open, setOpen] = useState(initialState)
  const [validated, setValidated] = useState(!validationText)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleConfirm = () => {
    handleClose()
    if (onConfirm) onConfirm()
  }

  return (
    <>
      {render && render({ onClick: handleOpen })}
      <Dialog
        {...rest}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
          <div>
            <div style={{ clear: 'both' }}>
              {validationText && (
                <ValidationText
                  text={validationText}
                  onChange={(validated) => setValidated(validated)}
                />
              )}
            </div>
            <div style={{ clear: 'both' }}>{content}</div>
          </div>
        </DialogContent>
        <DialogActions>
          {cancelText && (
            <Button onClick={handleClose} color="secondary">
              {cancelText}
            </Button>
          )}
          {confirmText && (
            <Button
              disabled={!validated || disableSubmit}
              onClick={handleConfirm}
              color="primary"
              autoFocus
            >
              {confirmText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AlertDialog
