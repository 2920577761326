import { useHistory } from 'react-router-dom'
import React, { Fragment, useState } from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import getFullName from './getFullName'
import getHumanReadableDate from './getHumanReadableDate'
import EmailTooltip from './EmailTooltip'
import PhoneTooltip from './PhoneTooltip'
import StatusSelector from './StatusSelector'
import { StatusLog } from './StatusLog'
import isOnWatchList from './isOnWatchList'
import { Funnel } from '../../../types'
import PracticeIndicator from './PracticeIndicator'

interface IFunnelTableRow {
  funnel: Funnel
  onStatusChange: any
}

const FunnelTableRow: React.FunctionComponent<IFunnelTableRow> = ({
  funnel,
  onStatusChange
}) => {
  const history = useHistory()
  const [open, setOpen] = useState(false)

  const handleClick = () => history.push(`/wachtlijst/${funnel.id}`)

  const { patient, statusLog, practice, location, status } = funnel
  const lastLogItem = statusLog[statusLog.length - 1]

  return (
    <Fragment>
      <TableRow key={funnel.id}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            <KeyboardArrowUpIcon style={{ rotate: open ? '0deg' : '180deg' }} />
          </IconButton>
        </TableCell>
        <TableCell
          align="right"
          padding="none"
          style={{ verticalAlign: 'middle' }}
        >
          <PracticeIndicator practiceName={practice?.name} />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{
            verticalAlign: 'middle',
            width: '50%',
            cursor: 'pointer'
          }}
          onClick={handleClick}
        >
          {getFullName(patient)}
        </TableCell>
        <TableCell
          align="right"
          style={{ verticalAlign: 'bottom', width: '22%' }}
        >
          {lastLogItem.user.firstName}
        </TableCell>
        <TableCell
          align="right"
          style={{ verticalAlign: 'bottom', width: '12%' }}
        >
          {getHumanReadableDate(new Date(lastLogItem.createdAt))}
        </TableCell>
        {isOnWatchList(status) && (
          <TableCell align="right" style={{ verticalAlign: 'bottom' }}>
            {location?.abbreviation}
          </TableCell>
        )}
        <TableCell style={{ verticalAlign: 'bottom', width: '5%' }}>
          {patient.email && <EmailTooltip email={patient.email} />}
        </TableCell>
        <TableCell style={{ verticalAlign: 'bottom', width: '5%' }}>
          {patient.phoneNumber && <PhoneTooltip phone={patient.phoneNumber} />}
        </TableCell>
        <TableCell style={{ verticalAlign: 'bottom' }}>
          <StatusSelector
            value={funnel.status.id}
            onChange={(event) =>
              onStatusChange({
                funnelId: funnel.id,
                statusId: event.target.value
              })
            }
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, borderBottomWidth: 0 }}
          colSpan={12}
        >
          <StatusLog
            // @ts-ignore
            funnelId={funnel.id}
            statusLog={funnel.statusLog}
            open={open}
            practice={practice}
            location={location}
          />
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

export default FunnelTableRow
