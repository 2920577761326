import { useEffect, useState } from 'react'
import useEffectAsync from '../hooks/useEffectAsync'
import to from '../utils/to'
import Select from 'react-select'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { useTranslation } from 'react-i18next'

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: '0px solid lightgray', // default border color
    borderBottom: '1px solid lightgray',
    borderRadius: 0
  })
}

const SingleSelectWrapper = ({
  input: { value, name, onChange },
  meta,
  getOptionValue,
  getOptionLabel,
  getOptions, // async
  defaultLabel,
  options: propOptions,
  label,
  optionsFilter,
  ...rest
}) => {
  const { t } = useTranslation('reactFinalForm')

  const [options, setOptions] = useState(propOptions || [])
  const [loading, setLoading] = useState(
    (propOptions && propOptions.length === 0) || options?.length === 0
  )
  const [error, setError] = useState(null)

  useEffect(() => {
    if (propOptions) {
      const options = propOptions?.map((option) => {
        return { label: getOptionLabel(option), value: getOptionValue(option) }
      })
      setOptions(options)
      if (options?.length > 0) setLoading(false)
    }
  }, [propOptions])

  useEffectAsync(async () => {
    if (getOptions) {
      let [options, error] = await to(getOptions())
      if (error) {
        setError(error)
      }
      options = options.map((option) => {
        return { label: getOptionLabel(option), value: getOptionValue(option) }
      })
      setOptions(options)
      setLoading(false)
    }
  }, [])

  if (loading) {
    return <LoadingIndicator />
  } else if (error) {
    return (
      <div>
        {t('errorOccurred')} {error}
      </div>
    )
  } else if (options) {
    const defaultOption = options.find(
      (option) => option.label === defaultLabel
    )
    const currentOption =
      options.find((option) => option.value === value) || defaultOption || null

    return (
      <Select
        {...rest}
        name={name}
        value={currentOption}
        placeholder={label}
        options={options}
        onChange={(option) => onChange(option.value)}
        styles={customStyles}
      />
    )
  }
}

export default SingleSelectWrapper
