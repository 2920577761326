import { Component } from 'react'
import { FormSpy } from 'react-final-form'
import diff from '../../utils/diff'

class AutoSave extends Component {
  constructor(props) {
    super(props)
    this.state = { values: props.values, submitting: false }
  }

  componentDidUpdate() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(this.save, this.props.debounce)
  }

  // NOTE: this method will be deprecated in React, so moved to componentDidUpdate
  // componentWillReceiveProps(nextProps) {
  //   if (this.timeout) {
  //     clearTimeout(this.timeout);
  //   }
  //   this.timeout = setTimeout(this.save, this.props.debounce);
  // }

  jsonEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b)
  }

  save = async () => {
    if (this.promise) {
      await this.promise
    }
    const { values, save } = this.props

    const difference = diff(this.state.values, values)
    if (Object.keys(difference).length) {
      // values have changed
      this.setState({ submitting: true, values })
      this.promise = save(difference)
      await this.promise
      delete this.promise
      this.setState({ submitting: false })
    }

    /**
     * NOTE: this prevents unnecessary saves, but also takes a performance hit.
     */
    // if (!this.jsonEqual(this.state.values, values)) {
    //   this.setState({ submitting: true, values });
    //   this.promise = save(values);
    //   await this.promise;
    //   delete this.promise;
    //   this.setState({ submitting: false });
    // }
  }

  render() {
    return <></>
  }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in debounce and save props nicely
const AutoSaveFormSpy = (props) => (
  <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />
)

export default AutoSaveFormSpy
