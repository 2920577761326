import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import { useForm } from 'react-final-form'
import { client } from '../../../../client'
import { useFieldValue } from '../../../../hooks/useFieldValue'
import GetAppIcon from '@material-ui/icons/GetApp'
import DeleteIcon from '@material-ui/icons/Delete'
import { dateTimeToVerboseString } from '../../../../utils/dates'
import MaterialTable from 'material-table'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Autocomplete from '@material-ui/lab/Autocomplete'
import MuiTextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import { invoiceStatusOptions } from './helpers'

const InvoicesList = ({ invoices }) => {
  const { t } = useTranslation('invoiceSection')

  const programId = useFieldValue('id')
  const form = useForm()
  const [open, setOpen] = useState(false)
  const [invoiceId, setInvoiceId] = useState()

  const handleSave = async (id, filename) => {
    await client.fetchFile(id, filename)
  }

  const handleRemoveInvoice = async (id) => {
    await client.deleteInvoice(id)
    const invoices = await client.getInvoicesByTreatmentProgram(programId)
    form.mutators.setValue('invoices', invoices)
    const activities2022 = await client.getActivities2022ByTreatmentProgram(
      programId
    )
    form.mutators.setValue('activities2022', activities2022)
    setOpen(false)
  }

  const handleChangeStatus = async (invoiceId, status) => {
    await client.updateInvoice(invoiceId, { status })
    const invoices = await client.getInvoicesByTreatmentProgram(programId)
    form.mutators.setValue('invoices', invoices)
  }

  return (
    <>
      <MaterialTable
        columns={[
          { title: t('factureNumber'), field: 'invoiceNumber' },
          { title: t('dateLabel'), field: 'date' },
          {
            field: 'status',
            title: 'Status',
            render: (rowData) => (
              <Autocomplete
                options={invoiceStatusOptions}
                value={rowData.status}
                getOptionLabel={(option) => option.description}
                getOptionSelected={(option, value) => option.id === value.id}
                onChange={(event, newValue) =>
                  //   @ts-ignore
                  handleChangeStatus(rowData.id, newValue)
                }
                renderInput={(params) => (
                  <MuiTextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: {
                        fontSize: 'inherit'
                      },
                      disableUnderline: true
                    }}
                  />
                )}
              />
            )
          }
        ]}
        data={invoices.map((invoice) => ({
          ...invoice,
          date: dateTimeToVerboseString(invoice.date)
        }))}
        title={t('facture')}
        options={{
          header: false,
          search: false,
          showTitle: false,
          toolbar: false,
          paging: false,
          showEmptyDataSourceMessage: false
        }}
        components={{
          Container: (props) => <Paper {...props} elevation={0} />
        }}
        actions={[
          {
            icon: () => <GetAppIcon color="primary" />,
            tooltip: t('tooltipSave'),
            onClick: (event, rowData) =>
              // @ts-ignore
              handleSave(rowData.file.id, rowData.file.filename)
          },
          {
            icon: () => <DeleteIcon color="disabled" />,
            tooltip: t('tooltipRemove'),
            onClick: (event, rowData) => {
              // @ts-ignore
              setInvoiceId(rowData.id)
              setOpen(true)
            }
          }
        ]}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>{t('dialogTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('dialogText')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {t('cancel')}
          </Button>
          <Button
            onClick={() => handleRemoveInvoice(invoiceId)}
            color="primary"
            autoFocus
          >
            {t('tooltipRemove')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default InvoicesList
