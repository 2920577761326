import React from 'react'
import { DataGrid } from '@material-ui/data-grid'

const StatsTable = ({ data }) => {
  const tRs = Object.keys(data.rawData[0])

  const columns = tRs.map((el) => ({ field: el, flex: 1 }))
  columns.push({ field: 'id', hide: true, flex: 1 })

  const rows = data.rawData.map((el, index) => ({ ...el, id: index }))

  return (
    <div style={{ padding: '0 45px 50px 45px' }}>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid rows={rows} columns={columns} pageSize={7} />
      </div>
    </div>
  )
}

export default StatsTable
