import { validateEmail } from '../../utils/validate'

const validate = async (values) => {
  const errors = {}
  if (!values.phoneNumber) {
    errors.phoneNumber = 'Voer ten minste een telefoonnummer in.'
  }
  if (values.email && !validateEmail(values.email)) {
    errors.email = 'Het opgegeven e-mailadres is ongeldig.'
  }
  if (!values.status) {
    errors.status = 'Geef een status op.'
  }
  return errors
}

export default validate
