import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { TextField } from 'mui-rff'
import { client } from '../../client'
import arrayMutators from 'final-form-arrays'
import { Field, Form } from 'react-final-form'
import { Async } from '../../react-final-form-wrappers'
import { useNotificationBar } from '../../components/NotificationBar'
import FormRow from '../RecordPage/FormRow'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'

const Condition = ({ when, contains, children }) => (
  <Field name={when} subscription={{ value: true }}>
    {({ input: { value } }) => {
      return value &&
        value.length &&
        value.findIndex((i) => i.label === contains) > -1
        ? children
        : null
    }}
  </Field>
)

const NewUserPageContent = () => {
  const { t } = useTranslation('newUserPage')
  const { showMessage, NotificationBar } = useNotificationBar()

  const promiseOptions = async (inputValue) => {
    const roles = await client.getRoleOptions()
    return roles.map((role) => ({
      value: role.id,
      label: role.description
    }))
  }

  const save = async ({
    email,
    title,
    initials,
    firstName,
    infix,
    lastName,
    partnerInfix,
    partnerName,
    agbCode,
    genderId,
    roles,
    practice
  }) => {
    if (roles.find((r) => r.label === 'Behandelaar')) {
      practice = practice || {}
    }
    const data = await client.addUser({
      email,
      title,
      initials,
      firstName,
      infix,
      lastName,
      partnerInfix,
      partnerName,
      agbCode,
      genderId,
      roles: roles.map((role) => ({
        id: role.value
      })),
      practice
    })
    if (data.status === 'success') {
      showMessage(t('success'))
    } else {
      showMessage(t('error'), 'error')
    }
  }

  return (
    <Form
      onSubmit={save}
      mutators={{
        ...arrayMutators
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <Paper>
            <Box p={4}>
              <Grid container spacing={2}>
                <Grid item md={true} sm={true} xs={true}>
                  <TextField
                    name="email"
                    label="E-mail"
                    type="email"
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item md={true} sm={true} xs={true}>
                  <Field
                    isMulti
                    name="roles"
                    style={{ width: '100%', marginTop: 20 }}
                    cacheOptions
                    defaultOptions
                    loadOptions={promiseOptions}
                    component={Async}
                    placeholder={t('rolesPlaceholder')}
                  />
                </Grid>
              </Grid>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={true}>
                  <TextField name="title" label={t('titleLabel')} fullWidth />
                </Grid>
              </Grid>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={2}>
                  <TextField
                    name="initials"
                    label={t('initialsLabel')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="firstName"
                    label={t('firstNameLabel')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField name="infix" label={t('infixLabel')} fullWidth />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    name="lastName"
                    label={t('lastNameLabel')}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container justify="center" spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    name="partnerInfix"
                    label={t('partnerInfixLabel')}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="partnerName"
                    label={t('partnerNameLabel')}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Condition when="roles" contains="Behandelaar">
                <FormRow>
                  <TextField name="agbCode" label="AGB-code" fullWidth />
                </FormRow>
                <FormRow>
                  <TextField
                    fullWidth
                    name="practice.name"
                    label={t('practiceNameLabel')}
                  />
                  <TextField
                    fullWidth
                    name="practice.agbCode"
                    label={t('practiceCodeLabel')}
                  />
                </FormRow>
              </Condition>
              <Grid container justify="center" spacing={2}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{ textTransform: 'none', marginTop: 20 }}
                  type="submit"
                >
                  {t('createUser')}
                </Button>
              </Grid>
            </Box>
          </Paper>
          {NotificationBar}
        </form>
      )}
    />
  )
}

export default NewUserPageContent
