export const withReactFinalForm =
  (Component, props) =>
  ({ input: { onChange, value, name }, meta: { initial }, ...rest }) => {
    return (
      <Component
        {...rest}
        {...props}
        value={value === '' ? null : value}
        initial={initial}
        name={name}
        onChange={onChange}
      />
    )
  }
