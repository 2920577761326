import { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { client } from '../../../client'
import AuthenticationService from '../../../services/AuthenticationService'
import PageLoadingIndicator from '../../../components/PageLoadingIndicator/PageLoadingIndicator'
import RichTextEditor from '../../../components/RichTextEditor/RichTextEditor'
import { useTranslation } from 'react-i18next'

const EditTemplatesSection = () => {
  const { t } = useTranslation('settingsPage')
  const userId = AuthenticationService.getUserId()

  const [state, setState] = useState({ text: '' })

  // Initialize
  useEffectAsync(async () => {
    const [practiceOptions, templateTypeOptions] = await Promise.all([
      client.getPracticeOptions(true),
      client.getTemplateTypeOptions()
    ])

    setState({
      practiceOptions,
      templateTypeOptions,
      practice: practiceOptions[0],
      templateType: templateTypeOptions[0],
      text: ''
    })
  }, [])

  // Load existing template
  useEffectAsync(async () => {
    if (state?.practice?.id && state?.templateType?.fieldName) {
      const response = await client.getTemplate(
        userId,
        state.practice.id,
        state.templateType.fieldName
      )
      if (
        response &&
        response.status === 'success' &&
        response?.data?.template?.text
      ) {
        setState((state) => ({ ...state, text: response.data.template.text }))
      } else {
        console.log('Geen custom template en geen default template!')
        setState((state) => ({ ...state, text: '' }))
      }
    }
  }, [state.practice, state.templateType])

  // Auto-save
  useEffectAsync(async () => {
    if (!state || !state.text) {
      return
    }
    await client.createTemplate(
      state.text,
      userId,
      state.practice.id,
      state.templateType.fieldName
    )
  }, [state.text])

  if (!state?.practice || !state?.templateType) {
    return <PageLoadingIndicator />
  }

  return (
    <form autoComplete="off">
      <Grid container spacing={2}>
        <Grid item>
          <FormControl style={{ width: 200 }}>
            <InputLabel>{t('editTemplatePractice')}</InputLabel>
            <Select
              value={state.practice.id}
              onChange={(e) =>
                setState((state) => ({
                  ...state,
                  practice: state.practiceOptions.find(
                    (p) => p.id === e.target.value
                  )
                }))
              }
            >
              {state.practiceOptions.map((practice) => (
                <MenuItem key={practice.id} value={practice.id}>
                  {practice.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl style={{ width: 200 }}>
            <InputLabel>{t('template')}</InputLabel>
            <Select
              value={state.templateType.id}
              onChange={(e) =>
                setState((state) => ({
                  ...state,
                  templateType: state.templateTypeOptions.find(
                    (t) => t.id === e.target.value
                  )
                }))
              }
            >
              {state.templateTypeOptions.map((template) => (
                <MenuItem key={template.id} value={template.id}>
                  {template.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item style={{ marginTop: 10 }}>
        <RichTextEditor
          value={state.text}
          onChange={(v) =>
            setState((state) => ({
              ...state,
              text: v
            }))
          }
        />
      </Grid>
    </form>
  )
}

export default EditTemplatesSection
