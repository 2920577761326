import { useState, useCallback } from 'react'
import { client } from '../../../client'
import { Location, Practice } from '../../../types'
import './styles.css'

// -----------------------------------------------------------------------------

type LocationSelectorProps = {
  locationOptions: Location[]
  onConfirm: (location: Location) => void
  onCancel: () => void
}
const LocationSelector = ({
  locationOptions,
  onConfirm,
  onCancel
}: LocationSelectorProps): JSX.Element => {
  const [id, setId] = useState<number>(NaN)

  const onOk = useCallback(() => {
    const location = locationOptions.find((l) => l.id === id)
    if (location) onConfirm(location)
  }, [id, locationOptions, onConfirm])

  return (
    <div>
      <select
        onChange={(e) => {
          e.stopPropagation() // don't send events to the form
          setId(parseInt(e.target.value))
        }}
      >
        <option key={0} value={NaN} selected disabled hidden>
          Select a location
        </option>
        {locationOptions.map((l) => (
          <option key={l.id} value={l.id}>
            {l.name}
          </option>
        ))}
      </select>
      <button
        type="button"
        className="add-button"
        onClick={onOk}
        disabled={isNaN(id)}
      >
        Ok
      </button>
      <button type="button" className="delete-button" onClick={onCancel}>
        Cancel
      </button>
    </div>
  )
}

// -----------------------------------------------------------------------------

type AddLocationButtonProps = {
  locationOptions: Location[]
  addLocation: (location: Location) => void
}
const AddLocationButton = ({
  locationOptions,
  addLocation
}: AddLocationButtonProps): JSX.Element => {
  const [selecting, setSelecting] = useState(false)

  const onConfirm = (location: Location) => {
    setSelecting(false)
    addLocation(location)
  }

  if (!selecting) {
    return (
      <button
        type="button"
        className="add-button"
        onClick={() => setSelecting(true)}
      >
        ➕ Add new location
      </button>
    )
  }
  return (
    <LocationSelector
      locationOptions={locationOptions}
      onConfirm={onConfirm}
      onCancel={() => setSelecting(false)}
    />
  )
}

// -----------------------------------------------------------------------------

type LocationRowProps = {
  location: Location
  remove: (location: Location) => void
}
const LocationRow = ({ location, remove }: LocationRowProps): JSX.Element => {
  return (
    <li>
      {location.name}
      <button
        type="button"
        className="delete-button"
        onClick={() => remove(location)}
      >
        Remove
      </button>
    </li>
  )
}

// -----------------------------------------------------------------------------

type LocationsFieldProps = {
  practice: Practice
  locationOptions: Location[]
}
const LocationsField = ({
  practice,
  locationOptions
}: LocationsFieldProps): JSX.Element => {
  // keep list of locations in local state
  const [locations, setLocations] = useState<Location[]>(practice.locations)

  const addLocation = useCallback(
    (location: Location) => {
      client.addPracticeLocation(practice.id, location.id)
      setLocations([...locations, location])
    },
    [locations, practice.id]
  )

  const removeLocation = useCallback(
    (location: Location) => {
      client.removePracticeLocation(practice.id, location.id)
      setLocations(locations.filter((l) => l.id !== location.id))
    },
    [locations, practice.id]
  )

  // Missing locations will cause generating invoices to fail
  // adding a warning sign helps admins to notice missing locations
  const hasNoLocations = locations.length === 0
  const noLocationsWarning = hasNoLocations ? '⚠️' : ''

  return (
    <details>
      <summary>Click to expand {noLocationsWarning}</summary>
      <ul>
        {locations.map((l) => (
          <LocationRow key={l.id} location={l} remove={removeLocation} />
        ))}
        <li>
          <AddLocationButton
            locationOptions={locationOptions}
            addLocation={addLocation}
          />
        </li>
      </ul>
    </details>
  )
}

export default LocationsField
