import { useEffect, useState } from 'react'

export function useAsyncData<T>(f: () => Promise<T>): [T | undefined, boolean] {
  const [data, setData] = useState<T | undefined>()
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    f().then((data) => {
      setData(data)
      setLoading(false)
    })
  }, [])
  return [data, loading]
}
