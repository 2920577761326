import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import warning from 'warning'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  input: {
    display: 'none'
  },
  checkbox: {
    color: theme.palette.primary.main + '!important'
  }
}))

/**
 * Control the type of value stored by passing in onSubmit.
 *
 * Examples:
 *     - if you only want the filename stored, return the filename in onSubmit.
 *     - if you submit the file to an API, you may want to store the returned record
 *
 * The updated `value` is then emitted to `onChange`.
 */
const DateCheckbox = (props) => {
  const { t } = useTranslation('dateCheckboxComponent')

  const { label, value, onChange } = props

  const classes = useStyles()

  warning(
    onChange,
    "DateCheckbox is a controlled content, but onChange wasn't set"
  )

  const handleCheck = () => {
    if (value) {
      // Clear date when there is a value
      onChange(null)
    } else {
      // Enter current date when there is no value
      onChange(new Date())
    }
  }

  return (
    <div>
      <Checkbox
        style={{ marginTop: 10 }}
        checked={!!value}
        color="primary"
        className={classes.checkbox}
        onChange={handleCheck}
      />
      <KeyboardDatePicker
        style={{ left: 10, top: -8 }}
        variant="inline"
        label={label}
        onChange={onChange}
        value={value === '' ? null : value}
        format={'dd-MM-yyyy'}
        invalidDateMessage={t('invalidDateMessage')}
      />
    </div>
  )
}

export default DateCheckbox
