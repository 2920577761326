import { setIn } from 'final-form'

const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const validateSchema = async (values, schema) => {
  if (typeof schema === 'function') {
    schema = schema()
  }

  try {
    await schema.validate(values, { abortEarly: false })
  } catch (e) {
    return e.inner.reduce((errors, error) => {
      return setIn(errors, error.path, error.message)
    }, {})
  }
}

export { validateEmail, validateSchema }
