import { Paper, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const ChartNoData = ({ title }) => {
  const { t } = useTranslation('statisticsPage')
  return (
    <>
      <Paper style={{ textAlign: 'center', padding: 20 }}>
        <Typography>{title}</Typography>
        <Typography variant="p">{t('noData')}</Typography>
      </Paper>
    </>
  )
}

export default ChartNoData
