import './styles.css'
import { client } from '../../../client'
import Page from '../../../layout/Page'
import { useAutosave } from './useAutosave'
import { usePsychologistOptions } from './initialData'
import { PracticeField } from './PracticeField'
import { useMemo, useState } from 'react'

const PsychologistListContent = (): JSX.Element => {
  client.clearCacheItem('psychologistOptions')
  client.clearCacheItem('practiceOptions')

  const psychologists = usePsychologistOptions()
  const [filter, setFilter] = useState('')

  const filteredPsychologists = useMemo(() => {
    return psychologists.filter((p) =>
      JSON.stringify(p).toLowerCase().includes(filter.toLowerCase())
    )
  }, [filter, psychologists])

  console.log({ filteredPsychologists })

  const onChange = useAutosave()

  return (
    <form onSubmit={(e) => e.preventDefault()} onChange={onChange}>
      <p>⚠️ Note: Agb Code must be unique (cannot repeat)</p>
      <label>Filter:</label>
      <input
        type="text"
        onChange={(e) => {
          e.stopPropagation()
          setFilter(e.target.value)
        }}
      />
      <table className="psychologistlistpage-table">
        <thead>
          <tr>
            <th>Id</th>
            <th>First Name</th>
            <th>Infix</th>
            <th>Last Name</th>
            <th>Initials</th>
            <th>Email</th>
            <th>Agb Code</th>
            <th>Phone Number</th>
            <th>Active</th>
            <th>Practice</th>
          </tr>
        </thead>
        <tbody>
          {filteredPsychologists.map((psychologist) => (
            <tr key={psychologist.id}>
              <td>
                <code>{psychologist.id}</code>
              </td>
              <td>
                <input
                  type="text"
                  name={`user.${psychologist.user.id}.firstName`}
                  defaultValue={psychologist.user.firstName}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`user.${psychologist.user.id}.infix`}
                  defaultValue={psychologist.user.infix || ''}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`user.${psychologist.user.id}.lastName`}
                  defaultValue={psychologist.user.lastName || ''}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`user.${psychologist.user.id}.initials`}
                  defaultValue={psychologist.user.initials || ''}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`user.${psychologist.user.id}.email`}
                  defaultValue={psychologist.user.email}
                  disabled
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`psychologist.${psychologist.id}.agbCode`}
                  defaultValue={psychologist.agbCode}
                />
              </td>
              <td>
                <input
                  type="text"
                  name={`psychologist.${psychologist.id}.phoneNumber`}
                  defaultValue={psychologist.phoneNumber || ''}
                />
              </td>
              <td>
                <input
                  type="checkbox"
                  name={`user.${psychologist.user.id}.isActive`}
                  defaultChecked={psychologist.user.isActive}
                />
              </td>
              <td style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
                <PracticeField psychologist={psychologist} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </form>
  )
}

const PsychologistListPage = (): JSX.Element => {
  return <Page content={<PsychologistListContent />} menu={false} />
}

export default PsychologistListPage
