import React, { useState } from 'react'
import RecordPageMenu from './RecordPageMenu'
import RecordPageContent from './RecordPageContent'
import Page from '../../layout/Page'

export const MenuContext = React.createContext(null)

const RecordPage = () => {
  const [menuState, setMenuState] = useState({
    hidden: []
  })

  const hideMenuItem = (item) =>
    setMenuState({
      hidden: [...menuState.hidden, item]
    })

  const isVisibleMenuItem = (item) => !menuState.hidden.includes(item)

  return (
    <MenuContext.Provider value={{ hideMenuItem, isVisibleMenuItem }}>
      <Page menu={<RecordPageMenu />} content={<RecordPageContent />} />
    </MenuContext.Provider>
  )
}

export default RecordPage
