// NOTE: person can be patient or psychologist

//todo: get generated types from backend repo
interface Patient {
    firstName: string;
    infix: string;
    lastName: string;
}
interface Psychologist {
    firstName: string;
    infix: string;
    lastName: string;
}
const getFullName = (person: Patient | Psychologist) => {
    const { firstName, infix, lastName } = person;
    return [firstName, infix, lastName].join(' ');
};

export default getFullName;