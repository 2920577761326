import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

export default function BackOfficePagination({ page, handleChange, pages }) {
  const classes = useStyles();

  if (!pages || pages < 2) return <></>;

  return (
    <div className={classes.root}>
      <Pagination count={pages} page={page} onChange={handleChange} />
    </div>
  );
}
