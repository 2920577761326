import TooltipButton from "./TooltipButton";
import EmailIcon from "mdi-material-ui/Email";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import {CopyToClipboard} from "react-copy-to-clipboard";
import IconButton from "@material-ui/core/IconButton";
import ClipboardIcon from "mdi-material-ui/Clipboard";
import React from "react";

interface IEmailTooltipProps {
    email: string
}
const EmailTooltip: React.FunctionComponent<IEmailTooltipProps> = ({ email }) => (
    <TooltipButton
        icon={EmailIcon}
        title={
            <div>
                <Link
                    target="_blank"
                    href={`mailto:${email}`}
                    color="inherit"
                    style={{ display: 'inline-block', textDecoration: 'none' }}
                >
                    <Typography variant="body2">{email}</Typography>
                </Link>
                <CopyToClipboard text={email}>
                    <IconButton style={{ display: 'inline-block' }} size="small">
                        <ClipboardIcon
                            fontSize="small"
                            color="inherit"
                            style={{ color: '#ffffff' }}
                        />
                    </IconButton>
                </CopyToClipboard>
            </div>
        }
    />
);

export default EmailTooltip;