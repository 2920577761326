import { useState } from 'react'
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
import LoginPage from './pages/LoginPage/LoginPage'
import Layout from './layout/Layout'
import useEffectAsync from './hooks/useEffectAsync'
import { AccessForbiddenPage } from './pages/AccessForbiddenPage'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ModalContainer } from './components/ModalContainer'
import { client } from './client'
import { ErrorBoundary } from 'react-error-boundary'
import authenticationService from './services/AuthenticationService'
import { useTranslation } from 'react-i18next'

const ErrorFallback = ({ error }) => {
  const { t } = useTranslation('errorFallbackApp')

  // check if error is usable
  if (!error) return <></>
  if (!(error instanceof Error)) return <></>

  console.log('got an error', error)

  switch (error.name) {
    case 'NotAuthenticatedException':
      authenticationService.logout()
      return <></>
    default:
      return (
        <div>
          <h3>
            {t('errorMessage')} {error.name}
          </h3>
          <pre style={{ color: 'red' }}>{error.message}</pre>
        </div>
      )
  }
}

const ProtectedRoute = (props) => {
  const { component: Component, ...rest } = props

  return (
    <Route
      {...rest}
      render={(rest) =>
        client.isLoggedIn() ? <Component {...rest} /> : <Redirect to="/login" />
      }
    />
  )
}

const App = () => {
  const [accessible, setAccessible] = useState(true)

  useEffectAsync(async () => {
    setAccessible(await client.isAccessible())
  }, [])

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => (window.location.href = '/')}
    >
      <CssBaseline />
      <ModalContainer />
      <Router>
        <Switch>
          {!accessible && <Route component={AccessForbiddenPage} />}
          <Route exact path="/login" component={LoginPage} />
          <ProtectedRoute path="/*" component={Layout} />
        </Switch>
      </Router>
    </ErrorBoundary>
  )
}

export default App
