import { makeStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import { Numeric0, Numeric4 } from 'mdi-material-ui'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '70%',
    margin: 'auto'
  },
  icon: {
    fontSize: 80,
    color: '#4b86aa'
  },
  link: {
    textDecoration: 'none',
    color: 'gray'
  }
}))

const FourOhFourPageContent = () => {
  const { t } = useTranslation('fourOhFourPage')
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Box display="flex">
        <Numeric4 className={classes.icon} />
        <Numeric0 className={classes.icon} />
        <Numeric4 className={classes.icon} />
      </Box>
      <Box mt={3}>
        <Typography paragraph>{t('doesNotExist')}</Typography>
        <Typography paragraph>
          {t('maybeHelps')}{' '}
          <Link to="/" className={classes.link}>
            {t('homePage')}
          </Link>{' '}
          {t('tryAgainn')}
        </Typography>
      </Box>
    </div>
  )
}

export default FourOhFourPageContent
