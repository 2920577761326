import TooltipButton from "./TooltipButton";
import PhoneIcon from "mdi-material-ui/Phone";
import Typography from "@material-ui/core/Typography";
import {CopyToClipboard} from "react-copy-to-clipboard";
import IconButton from "@material-ui/core/IconButton";
import ClipboardIcon from "mdi-material-ui/Clipboard";
import React from "react";

interface IPhoneTooltip {
    phone: string
}
const PhoneTooltip: React.FunctionComponent<IPhoneTooltip> = ({ phone }) => (
    <TooltipButton
        icon={PhoneIcon}
        title={
            <div>
                <Typography style={{ display: 'inline-block' }} variant="body2">
                    {phone.replace(/(.{2})/g, '$1 ')}
                </Typography>
                <CopyToClipboard text={phone}>
                    <IconButton style={{ display: 'inline-block' }} size="small">
                        <ClipboardIcon
                            fontSize="small"
                            color="inherit"
                            style={{ color: '#ffffff' }}
                        />
                    </IconButton>
                </CopyToClipboard>
            </div>
        }
    />
);

export default PhoneTooltip;