import StatsBarChart from './StatsBarChart'
import StatsLineChart from './StatsLineChart'
import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl'
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Paper
} from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import StatsTable from './StatsTable'

const CommonLineBarChart = ({ data }) => {
  const { t } = useTranslation('statisticsPage')
  const [value, setValue] = useState('bar')

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const renderBar = (value) => {
    switch (value) {
      case 'bar':
        return <StatsBarChart data={data} />
      case 'table':
        return <StatsTable data={data} />
      default:
        return <StatsLineChart data={data} />
    }
  }

  return (
    <>
      <Paper style={{ marginBottom: 10 }}>
        <Typography style={{ textAlign: 'center', margin: 20, paddingTop: 20 }}>
          {data.title}
        </Typography>
        <FormControl style={{ marginLeft: 50 }} component="fieldset">
          <RadioGroup
            row
            aria-label="chart-type"
            name="chart-type"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="bar"
              control={<Radio />}
              label={t('bar')}
            />
            <FormControlLabel
              value="line"
              control={<Radio />}
              label={t('line')}
            />
            <FormControlLabel
              value="table"
              control={<Radio />}
              label={t('table')}
            />
          </RadioGroup>
        </FormControl>
        {renderBar(value)}
      </Paper>
    </>
  )
}

export default CommonLineBarChart
