import { FieldArray } from 'react-final-form-arrays'
import { Field, FormSpy, useField } from 'react-final-form'
import { DatePicker, Select } from '../../../../react-final-form-wrappers'
import { TextField } from 'mui-rff'
import { client } from '../../../../client'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { OnChange } from 'react-final-form-listeners'
import Grid from '@material-ui/core/Grid'
import { useTranslation } from 'react-i18next'

/**
 * TODO: calculating totals and showing them is incredibly ugly.
 * Give it some love!
 */

const parseIntIgnoreEmpty = (value) => (value ? parseInt(value) : 0)

const sumDirect = (total, current) =>
  total + parseIntIgnoreEmpty(current.directMinutes)
const sumIndirect = (total, current) =>
  total + parseIntIgnoreEmpty(current.indirectMinutes)

const DirectTimeSummer = ({ values }) => {
  const totalDirect = values.activities
    ? values.activities.reduce(sumDirect, 0)
    : 0
  return <span>{totalDirect}</span>
}

const IndirectTimeSummer = ({ values }) => {
  const totalIndirect = values.activities
    ? values.activities.reduce(sumIndirect, 0)
    : 0
  return <span>{totalIndirect}</span>
}

const TotalTimeSummer = ({ values }) => {
  const total = values.activities
    ? values.activities.reduce(sumDirect, 0) +
      values.activities.reduce(sumIndirect, 0)
    : 0
  return <span style={{ marginLeft: '12px' }}>({total})</span>
}

const getFullName = (psychologist) => {
  if (!psychologist.user) {
    return ''
  }
  const { initials, infix, lastName } = psychologist.user
  return [initials, infix, lastName].join(' ')
}

const TimeRegistrationSection = (props) => {
  const { t } = useTranslation('timeRegistrationSection')

  const {
    input: { value: isLegacy }
  } = useField('isLegacy')

  const handleRemoveActivity = async (activity) => {
    await client.removeActivity(activity.id)
  }

  return (
    <>
      <FieldArray name="activities">
        {({ fields }) => {
          return (
            <>
              {fields.map((name, index) => {
                // Forward reference to functions so these can be used in this scope
                let changeDirectMinutes, changeIndirectMinutes
                return (
                  <Grid container key={name} spacing={1}>
                    <Field name={`${name}.directMinutes`} subscription={{}}>
                      {({ input: { onChange } }) => {
                        // NOTE: these are two hidden components that purely exist to pass on their onChange methods
                        // Assign to variable in higher scope
                        changeDirectMinutes = onChange
                        return <></>
                      }}
                    </Field>
                    <Field name={`${name}.indirectMinutes`} subscription={{}}>
                      {({ input: { onChange } }) => {
                        // Assign to variable in higher scope
                        changeIndirectMinutes = onChange
                        return <></>
                      }}
                    </Field>
                    <Grid item xs={2}>
                      <Field
                        name={`${name}.date`}
                        label={t('date')}
                        component={DatePicker}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Select
                        label={t('activity')}
                        getOptions={async () =>
                          isLegacy
                            ? client.getActivityOptions()
                            : client.getActivityOptions(false)
                        }
                        getOptionLabel={(i) => i.name}
                        getOptionValue={(i) => i.id}
                        name={`${name}.activity.id`}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Select
                        label={t('psychologist')}
                        getOptions={async () =>
                          isLegacy
                            ? client.getPsychologistOptions()
                            : client.getPsychologistOptions(true)
                        }
                        getOptionLabel={(i) => getFullName(i)}
                        getOptionValue={(i) => i.id}
                        name={`${name}.psychologist.id`}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        name={`${name}.directMinutes`}
                        label="Direct"
                        placeholder="Direct"
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <TextField
                        name={`${name}.indirectMinutes`}
                        label="Indirect"
                        placeholder="Indirect"
                      />
                    </Grid>
                    <OnChange name={`${name}.activity`}>
                      {async ({ id }) => {
                        // When an activity is selected, only its value is passed down.
                        // Therefore, we've lost the default minutes and we'll need to retrieve these again.
                        // TODO: come up with a more efficient solution
                        const activity = await client.getActivity(id)
                        changeDirectMinutes(activity.defaultDirectMinutes)
                        changeIndirectMinutes(activity.defaultIndirectMinutes)
                      }}
                    </OnChange>
                    <Grid item xs={1}>
                      <IconButton
                        aria-label="Delete"
                        onClick={() =>
                          handleRemoveActivity(fields.remove(index))
                        }
                      >
                        <DeleteIcon fontSize="small" color="disabled" />
                      </IconButton>
                    </Grid>
                  </Grid>
                )
              })}
              {fields.length > 0 && (
                <Grid container spacing={1}>
                  <Grid item xs={2} />
                  <Grid item xs={4} />
                  <Grid item xs={3} />
                  <Grid item xs={1}>
                    <FormSpy
                      subscription={{ values: true }}
                      component={DirectTimeSummer}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <FormSpy
                      subscription={{ values: true }}
                      component={IndirectTimeSummer}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <FormSpy
                      subscription={{ values: true }}
                      component={TotalTimeSummer}
                    />
                  </Grid>
                </Grid>
              )}
            </>
          )
        }}
      </FieldArray>
    </>
  )
}

export default TimeRegistrationSection
