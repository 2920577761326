import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'
import warning from 'warning'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  input: {
    display: 'none'
  },
  checkbox: {
    color: theme.palette.primary.main + '!important'
  }
}))

const SelectCheckbox = ({ options, label, value, onChange, variant }) => {
  const classes = useStyles()

  warning(
    onChange,
    "SelectCheckbox is a controlled content, but onChange wasn't set"
  )

  const handleChange = (event, newValue) => {
    onChange(newValue)
  }

  return (
    <Box display="flex">
      <Checkbox
        style={{ marginTop: 10 }}
        checked={!!value}
        color="primary"
        className={classes.checkbox}
      />
      <Autocomplete
        options={options}
        style={{ width: 300 }}
        getOptionLabel={(option) => option.name}
        getOptionSelected={(option, value) => option.id === value.id}
        value={!value ? null : value}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField {...params} label={label} variant={variant} />
        )}
      />
    </Box>
  )
}

export default SelectCheckbox
