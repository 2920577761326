import { AddPatientFab } from '../components/AddPatientFab'
import { makeStyles } from '@material-ui/core/styles'
import authenticationService from '../services/AuthenticationService'
import Box from '@material-ui/core/Box'
import Menu from './Menu'
import Container from '@material-ui/core/Container'

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default
  },
  toolbar: theme.mixins.toolbar
}))

const Page = ({ menu, content, ...rest }) => {
  const classes = useStyles()

  return (
    <div style={{ width: '100%' }}>
      <Box display="flex">
        <Menu>{menu && menu}</Menu>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Container disableGutters={true}>
            <Box m={3}>{content}</Box>
          </Container>
          {authenticationService.isBackOffice() && <AddPatientFab />}
        </main>
      </Box>
    </div>
  )
}

export default Page
