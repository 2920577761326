import Autocomplete from '@material-ui/lab/Autocomplete'

const AutocompleteWrapper = ({ onChange, renderInput, options, ...rest }) => (
  <Autocomplete
    {...rest}
    renderInput={renderInput}
    options={options}
    onChange={(event, newValue) => {
      onChange(newValue)
    }}
  />
)

export default AutocompleteWrapper
