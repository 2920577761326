import { TextField } from 'mui-rff';
import { client } from '../../../../client';
import FormRow from '../../FormRow';
import { Field, SingleSelect } from '../../../../react-final-form-wrappers';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';

const HealthInsuranceSection = () => {
  const { t } = useTranslation('healthInsuranceSection');

  const [options, setOptions] = useState([]);

  const form = useFormState().values;

  useEffect(() => {
    (async () => {
      const options = await client.getInsurerOptions();
      setOptions(
        options.filter(
          (option) => !option.disabled || option.id === form.insurer?.id
        )
      );
    })();
  }, []);

  return (
    <>
      <FormRow>
        <Field
          style={{ width: 625 }}
          label={t('insurerLabel')}
          options={options}
          getOptionLabel={(i) => `${i?.abbreviation || i.name} (${i.uzovi}) ${i.note ? ' ' + i.note + ' ' : ' '}`}
          getOptionValue={(i) => i.id}
          name="insurer.id"
          component={SingleSelect}
        />
      </FormRow>

      <FormRow>
        <TextField
          style={{ width: 600 }}
          name={'insuranceNumber'}
          label={t('insuredNumberLabel')}
        />
      </FormRow>
    </>
  );
};

export default HealthInsuranceSection;
