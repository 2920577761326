import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useTranslation } from 'react-i18next'

const ModalContainer = () => {
  const { t } = useTranslation('modalContainerComponent')

  const closeDialog = useStoreActions((actions) => actions.user.closeDialog)
  const {
    isOpen = false,
    title = '',
    text = '',
    cancelText = t('cancelText'),
    confirmText = 'OK',
    onConfirm = () => undefined
  } = useStoreState((state) => state.user.dialog)

  const handleConfirm = () => {
    onConfirm()
    closeDialog()
  }

  return (
    <Dialog open={isOpen}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="secondary" autoFocus>
          {cancelText}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ModalContainer
