import { FormEvent, useCallback, useEffect, useRef, useState } from 'react'
import { client } from '../../../client'
import Page from '../../../layout/Page'
import { Location } from '../../../types'
import './style.css'

const useLocationOptions = (): [Location[], (location: Location) => void] => {
  const [locations, setLocations] = useState<Location[]>([])

  useEffect(() => {
    client.getLocationOptions().then(setLocations)
  }, [])

  const addLocation = useCallback(
    (location: Location) => {
      setLocations([...locations, location])
    },
    [locations]
  )
  return [locations, addLocation]
}

const LocationListContent = (): JSX.Element => {
  client.clearCacheItem('locationOptions')
  const [locations, addLocation] = useLocationOptions()
  const [loading, setLoading] = useState(false)

  const updates = useRef<Record<string, Partial<Location>>>({})
  const timerHandle = useRef(0)

  const save = useCallback(() => {
    const entries = Object.entries(updates.current)
    updates.current = {}
    for (const [id, data] of entries) {
      client.updateLocation(id, data).catch(console.error)
    }
  }, [])

  const onChange = useCallback(
    (e: FormEvent) => {
      const elem = e.target as HTMLInputElement
      const name = elem.name
      const value = elem.type === 'checkbox' ? elem.checked : elem.value
      const [id, field] = name.split('.')
      if (!(id in updates.current)) updates.current[id] = {}
      updates.current[id][field] = value
      window.clearTimeout(timerHandle.current)
      timerHandle.current = window.setTimeout(save, 1000)
      console.log({ updates })
    },
    [save]
  )

  const createLocation = useCallback(async () => {
    setLoading(true)
    const defaultData: Omit<Location, 'id'> = {
      name: Math.floor(Math.random() * 1000000000000).toString(16),
      abbreviation: '',
      address: Math.floor(Math.random() * 1000000000000).toString(16),
      city: '',
      postalCode: '',
      invoiceOnly: false
    }
    const location = await client.createLocation(defaultData)
    addLocation(location)
    setLoading(false)
  }, [addLocation])

  // save updates on exit
  useEffect(() => save, [save])

  return (
    <form onSubmit={(e) => e.preventDefault()} onChange={onChange}>
      <h1>Locations</h1>
      <p>
        ⚠️ Note: fields <b>Name</b> and <b>Address</b> must be unique (cannot
        repeat)
      </p>
      <p>
        ⚠️ Note 2: currently, there is no option to delete locations since this
        could destroy existing patient files and invoices. If a location was
        created by accident, it is recommended to set it to <b>invoice only</b>{' '}
        and use it for something else in the future
      </p>
      <table className="locationlistpage-table">
        <thead>
          <th>Id</th>
          <th>Name</th>
          <th>Address</th>
          <th>City</th>
          <th>Postal Code</th>
          <th>Abbreviation</th>
          <th>Invoice Only</th>
        </thead>
        <tbody>
          {locations.map((location) => (
            <tr key={location.id}>
              <td>
                <code>{location.id}</code>
              </td>
              <td>
                <input
                  name={`${location.id}.name`}
                  type="text"
                  defaultValue={location.name}
                  autoComplete="off"
                />
              </td>
              <td>
                <input
                  name={`${location.id}.address`}
                  type="text"
                  defaultValue={location.address}
                  autoComplete="off"
                />
              </td>
              <td>
                <input
                  name={`${location.id}.city`}
                  type="text"
                  defaultValue={location.city}
                  autoComplete="off"
                />
              </td>
              <td>
                <input
                  name={`${location.id}.postalCode`}
                  type="text"
                  defaultValue={location.postalCode}
                  autoComplete="off"
                />
              </td>
              <td>
                <input
                  name={`${location.id}.abbreviation`}
                  type="text"
                  defaultValue={location.abbreviation}
                  autoComplete="off"
                />
              </td>
              <td>
                <input
                  name={`${location.id}.invoiceOnly`}
                  type="checkbox"
                  defaultChecked={location.invoiceOnly}
                  autoComplete="off"
                />
              </td>
            </tr>
          ))}
          <tr>
            <td colSpan={100}>
              <button
                className="add-location-button"
                type="button"
                onClick={createLocation}
                disabled={loading}
              >
                ➕ Create new location
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  )
}

const LocationListPage = (): JSX.Element => {
  return <Page content={LocationListContent} menu={false} />
}

export default LocationListPage
