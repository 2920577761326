import FormRow from '../../FormRow'
import { Field, RichTextEditor } from '../../../../react-final-form-wrappers'

const NotesSection = ({ name }) => {
  return (
    <FormRow>
      <Field fullWidth multiline name={name} component={RichTextEditor} />
    </FormRow>
  )
}

export default NotesSection
