import { useMemo } from 'react'
import { useField } from 'react-final-form'
import { getFullName } from './helpers'
import { filterPrices } from '../TimeRegistration2022Section/ConsultationPriceField'
import { Checkbox, TableCell, TableRow } from '@material-ui/core'
import isValid from 'date-fns/esm/fp/isValid/index.js'
import { nl } from 'date-fns/locale'
import { format } from 'date-fns'

//actually, the file/component name is misleading - it is not only for activities from 2022, but from all activities from 2022 forward
//todo - change naming thrpoughout the app
const InvoiceActivity2022Row = ({
  name,
  consultationTypes,
  psychologistOptions,
  durationOptions,
  prices,
  handleActivitySelect,
  isSelected
}) => {
  const {
    input: { value: activity }
  } = useField(name)

  const {
    input: { value: treatmentType }
  } = useField('treatmentType')

  const consultationType = useMemo(
    () =>
      consultationTypes?.find((el) => el.id === activity?.consultationType?.id)
        ?.name,
    [consultationTypes, activity?.consultationType?.id]
  )

  const psychologist = useMemo(() => {
    const psychologistObject = psychologistOptions?.find(
      (el) => el.id === activity?.psychologist?.id
    )
    const formattedPsychologist =
      psychologistObject && getFullName(psychologistObject)
    return formattedPsychologist
  }, [psychologistOptions, activity?.psychologist?.id])

  const duration = useMemo(() => {
    const durationObject = durationOptions?.find(
      (el) => el.id === activity?.duration?.id
    )
    return durationObject?.value
  }, [durationOptions, activity?.duration?.id])

  const consultationPrice = useMemo(() => {
    const empty = { value: '0' }
    if (!prices) return empty
    const price = prices
      .filter((price) => filterPrices(price, activity, treatmentType))
      .shift()
    if (!price) return empty
    return price
  }, [prices, activity, treatmentType])

  const formattedDate = useMemo(() => {
    if (!activity?.date) return ''
    const date = new Date(activity.date)
    if (!isValid(new Date(date))) return ''
    return format(new Date(date), 'Pp', {
      locale: nl
    })
  }, [activity?.date])

  // @ts-ignore
  return (
    <>
      {activity?.id && (
        <TableRow>
          <TableCell>
            <Checkbox
              color="primary"
              disabled={
                !!activity.invoiceRow ||
                !activity?.date ||
                !consultationType ||
                typeof duration === 'undefined' ||
                !psychologist ||
                !consultationPrice.value
              }
              onChange={(e) =>
                handleActivitySelect(e.target.checked, {
                  id: activity.id,
                  date: activity.date,
                  amount: consultationPrice.value,
                  name,
                  description: `${consultationType} (${duration} min.)`
                })
              }
              checked={isSelected}
            />
          </TableCell>
          <TableCell>{formattedDate}</TableCell>
          <TableCell>{consultationType}</TableCell>
          <TableCell>{psychologist}</TableCell>
          <TableCell>{duration}</TableCell>
          <TableCell>{consultationPrice.value}</TableCell>
          <TableCell style={{ textAlign: 'center' }}>
            <Checkbox disabled checked={!!activity.invoiceRow} />
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
export default InvoiceActivity2022Row
