/**
 * This is a shameless copy of deep-object-diff where a removed value of a
 * property is reflected in the diff object as `null` instead of `undefined`,
 * allowing us to submit to the API without hassle (`undefined` fails).
 * @param d
 * @returns {boolean}
 */

export const isDate = (d) => d instanceof Date
export const isEmpty = (o) => Object.keys(o).length === 0
export const isObject = (o) => o != null && typeof o === 'object'
export const properObject = (o) =>
  isObject(o) && !o.hasOwnProperty ? { ...o } : o

const diff = (lhs, rhs) => {
  if (lhs === rhs) return {} // equal return no diff

  if (!isObject(lhs) || !isObject(rhs)) return rhs // return updated rhs

  const l = properObject(lhs)
  const r = properObject(rhs)

  const deletedValues = Object.keys(l).reduce((acc, key) => {
    // eslint-disable-next-line no-prototype-builtins
    return r.hasOwnProperty(key) ? acc : { ...acc, [key]: null } // originally this was undefined
  }, {})

  if (isDate(l) || isDate(r)) {
    if (l.valueOf() === r.valueOf()) return {}
    return r
  }

  return Object.keys(r).reduce((acc, key) => {
    // always return identifiers
    // eslint-disable-next-line no-prototype-builtins
    if (!l.hasOwnProperty(key) || key === 'id') {
      return { ...acc, [key]: r[key] }
    } // return added r key

    const difference = diff(l[key], r[key])

    if (isObject(difference) && isEmpty(difference) && !isDate(difference)) {
      return acc
    } // return no diff

    return { ...acc, [key]: difference } // return updated key
  }, deletedValues)
}

export default diff
