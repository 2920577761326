const renderComponent = (props, name) => {
  const { render, children, component: Component, ...rest } = props
  if (Component) {
    return (
      <Component {...rest} render={render}>
        {children}
      </Component>
    )
  }
  if (render) {
    return render({ ...rest, children }) // inject children back in
  }
  if (typeof children !== 'function') {
    // istanbul ignore next
    if (process.env.NODE_ENV !== 'production') {
      console.error(
        `Warning: Must specify either a render prop, a render function as children, or a component prop to ${name}`
      )
    }
    return null // warning will alert developer to their mistake
  }
  return children(rest)
}

export default renderComponent
