import { useRef, useCallback, useState } from 'react'
import { Psychologist, Practice } from '../../../types'
import { usePracticeOptions } from './initialData'

type PracticeSelectorProps = {
  psychologist: Psychologist
  onFinish: (practice: Practice) => void
}
const PracticeSelector = ({
  psychologist,
  onFinish
}: PracticeSelectorProps): JSX.Element => {
  const practices = usePracticeOptions()
  const selectRef = useRef<HTMLSelectElement>(null)
  const onClick = useCallback(() => {
    const id = parseInt(selectRef.current?.value || 'NaN')
    const practice = practices.find((p) => p.id === id)
    console.log({ id, practice })
    if (practice) onFinish(practice)
  }, [onFinish, practices])
  return (
    <>
      <select
        name={`psychologist.${psychologist.id}.practice.id`}
        ref={selectRef}
        defaultValue={psychologist.practice?.id}
      >
        {practices.map((p) => (
          <option
            key={p.id}
            value={p.id}
            label={p.name}
            selected={p.id === psychologist.practice?.id}
          />
        ))}
      </select>
      <button type="button" onClick={onClick}>
        Ok
      </button>
    </>
  )
}

export type PracticeFieldProps = { psychologist: Psychologist }
export const PracticeField = ({
  psychologist
}: PracticeFieldProps): JSX.Element => {
  const [selecting, setSelecting] = useState(false)
  const selectedName = useRef<string>()

  if (selecting) {
    return (
      <PracticeSelector
        psychologist={psychologist}
        onFinish={(practice: Practice) => {
          setSelecting(false)
          selectedName.current = practice.name
        }}
      />
    )
  }

  return (
    <>
      {selectedName.current || psychologist.practice?.name}
      <button
        type="button"
        style={{ display: 'inline-block' }}
        onClick={() => setSelecting(true)}
      >
        Change
      </button>
    </>
  )
}
