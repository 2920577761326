import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import useEffectAsync from '../../../hooks/useEffectAsync'
import groupBy from 'lodash.groupby'
import CommonLineBarChart from '../charts/CommonLineBarChart'
import { Autocomplete } from '@material-ui/lab'
import { Paper, TextField } from '@material-ui/core'
import { randomColor } from '../StatisticsPageContent'
import { client } from '../../../client'
import ChartNoData from '../charts/ChartNoData'

const AllChartsInOneFilter = ({
  convertedData,
  getPsychologists,
  rawData,
  title
}) => {
  const { t } = useTranslation('statisticsPage')

  const [therapists, setTherapists] = useState([])
  const [filterData, setFilterData] = useState()

  useEffect(() => {
    setFilterData(convertedData)
  }, [convertedData])

  useEffect(() => {
    let newFilter = []
    for (const therapist of therapists) {
      const part = convertedData.filter((el) => el.naam === therapist)
      newFilter = [...newFilter, ...part]
    }
    setFilterData(newFilter)
  }, [therapists])

  const data = []
  const bars = []
  const mapData = groupBy(filterData, 'maand')

  for (const month in mapData) {
    const dataMonth = {}
    dataMonth.name = month

    for (const psychologistMonth of mapData[month]) {
      dataMonth[psychologistMonth.naam] = psychologistMonth.aantal

      const find = bars.find((el) => el.name === psychologistMonth.naam)
      if (!find)
        bars.push({ name: psychologistMonth.naam, color: randomColor() })
    }
    data.push(dataMonth)
  }

  const handleChange = (event, value) => {
    setTherapists(value)
  }

  const resData = { rawData, data, bars, title, brush: { endIndex: 1 } }
  return (
    <>
      <Paper style={{ padding: 20, marginBottom: 10 }}>
        {getPsychologists.length && (
          <Autocomplete
            onChange={handleChange}
            multiple
            id="psychologist-tags"
            options={getPsychologists}
            getOptionLabel={(option) => option}
            defaultValue={getPsychologists}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label={t('selectPsychologists')}
                placeholder={t('choose')}
              />
            )}
          />
        )}
        <CommonLineBarChart data={resData} />
      </Paper>
    </>
  )
}

const TreatmentsPerMonthSplitByTherapist = () => {
  const { t } = useTranslation('statisticsPage')
  const [data, setData] = useState([])

  useEffectAsync(async () => {
    setData(await client.getTreatmentsPerMonthSplitByTherapist())
  }, [])

  const title = t('getTreatmentsPerMonthSplitByTherapist')
  if (!data.length) return <ChartNoData title={title} />

  const convertedData = data.map((item) => {
    const newItem = { ...item }
    delete newItem.jaar
    return {
      ...newItem,
      maand: `${item.maand} ${item.jaar}`
    }
  })

  const getPsychologists = [...new Set(convertedData.map((el) => el.naam))]

  return (
    <AllChartsInOneFilter
      title={title}
      rawData={data}
      convertedData={convertedData}
      getPsychologists={getPsychologists}
    />
  )
}

export default TreatmentsPerMonthSplitByTherapist
