import { useState } from 'react'
import { client } from '../../../client'
import useEffectAsync from '../../../hooks/useEffectAsync'
import { Psychologist, Practice } from '../../../types'

export const usePsychologistOptions = (): Psychologist[] => {
  const [psychologists, setPsychologists] = useState<Psychologist[]>([])
  useEffectAsync(async () => {
    const unsorted = await client.getPsychologistOptions(false)
    const sorted = unsorted.sort((a, b) =>
      a.user.lastName.localeCompare(b.user.lastName)
    )
    setPsychologists(sorted)
  }, [])
  return psychologists
}

export const usePracticeOptions = (): Practice[] => {
  const [practices, setPractices] = useState<Practice[]>([])
  useEffectAsync(async () => {
    const unsorted = await client.getPracticeOptions()
    const sorted = unsorted.sort((a, b) => a.name.localeCompare(b.name))
    setPractices(sorted)
  }, [])
  return practices
}
