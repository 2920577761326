import Grid from '@material-ui/core/Grid'
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { useState } from 'react'

export const DateTimePicker = ({
  input: { onChange, name },
  meta: { initial },
  labelDate,
  labelTime,
  ...rest
}) => {
  const [date, setDate] = useState(initial ? new Date(initial) : new Date())
  const [time, setTime] = useState(initial ? new Date(initial) : new Date())

  const getDateString = (date) => {
    const year = date.getFullYear()
    const month = date.getMonth() + 1 // Jan is 0, dec is 11
    const day = date.getDate()
    return '' + year + '-' + month + '-' + day
  }

  const getTimeString = (date) => {
    return date.getHours() + ':' + date.getMinutes() + ':00'
  }

  const onChangeDate = (d) => {
    setDate(d)
    if (d && time) {
      const timeString = getTimeString(time)
      const dateString = getDateString(d)

      const newDate = new Date(dateString + ' ' + timeString)
      onChange(newDate)
    }
  }

  const onChangeTime = (t) => {
    setTime(t)
    if (t && date) {
      const timeString = getTimeString(t)
      const dateString = getDateString(date)

      const newDate = new Date(dateString + ' ' + timeString)
      onChange(newDate)
    }
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <KeyboardDatePicker
          {...rest}
          autoOk
          variant="inline"
          label={labelDate}
          format="dd-MM-yyyy"
          invalidDateMessage="Ongeldige datum (gebruik dag-maand-jaar)"
          value={date}
          initial={initial}
          name={name}
          onChange={onChangeDate}
        />
      </Grid>
      <Grid item xs={4}>
        <KeyboardTimePicker
          {...rest}
          autoOk
          variant="inline"
          format="HH:mm"
          label={labelTime}
          invalidDateMessage="Ongeldige tijd (gebruik HH:mm)"
          value={time}
          initial={initial}
          name={name}
          onChange={onChangeTime}
        />
      </Grid>
    </Grid>
  )
}
