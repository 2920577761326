import { useState } from 'react'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { AlertDialog } from '../AlertDialog'
import { useField } from 'react-final-form'
import { client } from '../../client'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  filenameLabel: {
    marginLeft: 30
  }
})

const FileUploadButton = ({ label, name, type, value, onChange }) => {
  const {
    input: { value: programId }
  } = useField('id')
  const { t } = useTranslation('fileUploadButtonComponent')
  const [progress, setProgress] = useState(0)
  const [uploading, setUploading] = useState(false)
  const classes = useStyles()

  const handleSubmit = async (event) => {
    setUploading(true)

    const selectedFile = event.target.files[0]

    const response = await client.uploadFile(
      selectedFile,
      programId,
      type,
      (progressEvent) => {
        setProgress((progressEvent.loaded / progressEvent.total) * 100)
      }
    )

    onChange(response.data)
    setProgress(0)
    setUploading(false)
  }

  const handleRemove = async () => {
    await client.removeFile(value.id)
    onChange(null)
  }

  const handleFetch = async () => {
    await client.fetchFile(value.id, value.filename)
  }

  return (
    <div>
      <Button color="primary" component="label">
        <AttachFileIcon />
        {label}
        <Input
          name={name}
          id={name}
          type="file"
          style={{ display: 'none' }}
          onChange={handleSubmit}
        />
      </Button>
      {value && (
        <div>
          <InputLabel
            htmlFor={name}
            className={classes.filenameLabel}
            style={{ display: 'inline' }}
          >
            <Typography display="inline">
              <Link onClick={handleFetch}>{value.filename}</Link>
            </Typography>
          </InputLabel>
          <AlertDialog
            text={t('alertDialogText')}
            title={t('alertDialogTitle')}
            cancelText={t('alertDialogCancel')}
            confirmText={t('alertDialogConfirm')}
            onConfirm={handleRemove}
            render={({ onClick }) => (
              <IconButton
                style={{ marginLeft: 20 }}
                aria-label="Delete"
                onClick={onClick}
              >
                <DeleteIcon fontSize="small" color="disabled" />
              </IconButton>
            )}
          />
        </div>
      )}
      {uploading && <LinearProgress variant="determinate" value={progress} />}
    </div>
  )
}

// FileUploadButton.propTypes = {
//   label: PropTypes.string.isRequired,
//   name: PropTypes.string.isRequired
// };

export default FileUploadButton
