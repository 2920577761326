import { useState } from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import { client } from '../../client'
import useEffectAsync from '../../hooks/useEffectAsync'
import MaterialTable from 'material-table'
import icons from '../../utils/icons'
import { useNotificationBar } from '../../components/NotificationBar'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'

const isValidIP = (str) => {
  const octet = '(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]?|0)'
  const regex = new RegExp(`^${octet}\\.${octet}\\.${octet}\\.${octet}$`)
  return regex.test(str)
}

const columns = [
  {
    title: '#',
    field: 'id',
    sorting: false,
    defaultSort: 'desc',
    cellStyle: {
      width: 10
    }
  },
  {
    title: 'IP-adres',
    field: 'address',
    sorting: false
  }
]

const AddressTable = ({ addresses }) => {
  return (
    <MaterialTable
      data={addresses}
      columns={columns}
      icons={icons}
      options={{
        paging: false,
        toolbar: false
      }}
    />
  )
}

const WhitelistPageContent = () => {
  const { t } = useTranslation('whitelistPage')

  const [address, setAddress] = useState('')
  const [addresses, setAddresses] = useState([])
  const { showMessage, NotificationBar } = useNotificationBar({
    vertical: 'bottom',
    horizontal: 'center'
  })

  useEffectAsync(async () => {
    await getAddresses()
  }, [])

  const getAddresses = async () => {
    const addresses = await client.getWhitelistedAddresses()
    setAddresses(addresses)
  }

  const handleSubmit = async () => {
    if (!isValidIP(address)) {
      showMessage(`${t('notValidIp')} 211.137.117.210`, 'error')
      return
    }
    try {
      const response = await client.addIpAddress(address)
      if (response.status === 'fail') {
        showMessage(response.data.address, 'error')
      } else {
        showMessage(t('ipAddedToWhitelist'))
      }
      await getAddresses()
    } catch (e) {
      showMessage(t('addingIpError'))
    }
  }

  return (
    <Paper>
      <Box display="flex" mb={1} alignItems="center" justifyContent="center">
        <TextField
          id="address"
          label="IP-adres"
          onChange={(event) => setAddress(event.target.value)}
          required
        />
      </Box>
      <Box display="flex" mb={2} alignItems="center" justifyContent="center">
        <Button
          onClick={handleSubmit}
          variant="outlined"
          color="primary"
          style={{ textTransform: 'none' }}
        >
          {t('buttonText')}
        </Button>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <AddressTable addresses={addresses} />
      </Box>
      {NotificationBar}
    </Paper>
  )
}

export default WhitelistPageContent
