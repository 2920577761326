/*
 * This wrapper can wrap material-ui's Switch and Checkbox components.
 */

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Typography from '@material-ui/core/Typography'

export const withReactFinalFormToggle =
  (Component) =>
  ({
    input: { checked, value, name, onChange, className, ...restInput },
    meta,
    label,
    labelPlacement,
    labelVariant = 'body1',
    ...rest
  }) =>
    (
      <FormControlLabel
        control={
          <Component
            className={className}
            color="primary"
            name={name}
            inputProps={restInput}
            onChange={onChange}
            checked={checked}
            value={value}
          />
        }
        label={<Typography variant={labelVariant}>{label}</Typography>}
        labelPlacement={labelPlacement}
      />
    )
