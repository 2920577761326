import { useState, useEffect } from 'react'
import { client } from '../../../client'
import { Practice, Location } from '../../../types'

export const useInitialData = (): [Practice[], Location[]] => {
  const [practices, setPractices] = useState<Practice[]>([])
  const [locationOptions, setLocationOptions] = useState<Location[]>([])

  useEffect(() => {
    client.getPracticeOptions(false).then(sortPractices).then(setPractices)
    client.getLocationOptions().then(sortLocations).then(setLocationOptions)
  }, [])

  return [practices, locationOptions]
}

// -------------------------------- helpers ------------------------------------

const sortPractices = (arr: Practice[]): Practice[] =>
  arr.slice().sort((a, b) => a.name.localeCompare(b.name))

const sortLocations = (arr: Location[]): Location[] =>
  arr.slice().sort((a, b) => a.name.localeCompare(b.name))
