import { Field } from 'react-final-form'
import { showErrorOnChange } from 'mui-rff'
import { default as MuiTextField } from '@material-ui/core/TextField/TextField'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  helperText: {
    color: '#ff9800'
  }
}))

// This is simply a copy of the TextField export for "mui-rff"
export const WarningTextField = (props) => {
  const { name, type, fieldProps, ...rest } = props

  return (
    <Field
      name={name}
      type={type}
      render={({ input, meta }) => (
        <WarningTextFieldWrapper
          input={input}
          meta={meta}
          name={name}
          type={type}
          {...rest}
        />
      )}
      {...fieldProps}
    />
  )
}

// This is also largely a copy of mui-rff's TextField content, but the
// meta.data.warning is added, which provides the warning message.
const WarningTextFieldWrapper = (props) => {
  const {
    input: { name, value, type, onChange, ...restInput },
    meta,
    required,
    fullWidth = true,
    helperText,
    showError = showErrorOnChange,
    ...rest
  } = props

  const { error, data, submitError } = meta
  const isError = showError({ meta })

  const classes = useStyles()

  return (
    <MuiTextField
      fullWidth={fullWidth}
      helperText={
        data?.warning
          ? data.warning
          : isError
          ? error || submitError
          : helperText
      }
      error={isError}
      onChange={onChange}
      name={name}
      value={value}
      type={type}
      required={required}
      inputProps={{ required, ...restInput }}
      FormHelperTextProps={{
        className: classes.helperText
      }}
      {...rest}
    />
  )
}
