import Cancel from '@material-ui/icons/Cancel'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import { useTranslation } from 'react-i18next'

const NotAccessible = () => {
  const { t } = useTranslation('accessForbiddenPage')

  return (
    <Container fixed>
      <Grid container alignContent="center">
        <Grid item xs={2} style={{ textAlign: 'center' }}>
          <Cancel style={{ fontSize: 100 }} fontSize="large" />
        </Grid>
        <Grid item xs={10}>
          <Typography style={{ marginTop: 20 }} variant="h5" component="h3">
            {t('title')}
          </Typography>
          <Typography component="p">{t('paragraph')}</Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default NotAccessible
