import { useState, useContext } from 'react'
import arrayMutators from 'final-form-arrays'
import { Form } from 'react-final-form'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { withRouter } from 'react-router-dom'
import { client } from '../../client'
import useEffectAsync from '../../hooks/useEffectAsync'
import { AutoSave } from '../../components/AutoSave'
import FormActions from '../FormActions'
import FormSection from './FormSection'
import { TreatmentProgramSection } from './sections/TreatmentProgramSection'
import { TreatmentStatusSection } from './sections/TreatmentStatusSection'
import { PatientDetailsSection } from './sections'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { AlertDialog } from '../../components/AlertDialog'
import { PageLoadingIndicator } from '../../components/PageLoadingIndicator'
import HealthInsuranceSection from './sections/HealthInsuranceSection/HealthInsuranceSection'
import TherapistSection from './sections/TherapistSection/TherapistSection'
import ReferrerSection from './sections/ReferrerSection/ReferrerSection'
import StartTreatmentProgramSection from './sections/StartTreatmentProgramSection/StartTreatmentProgramSection'
import NotesSection from './sections/NotesSection/NotesSection'
import TreatmentPlanSection from './sections/TreatmentPlanSection/TreatmentPlanSection'
import SessionNotesSection from './sections/SessionNotesSection/SessionNotesSection'
import EndTreatmentProgramSection from './sections/EndTreatmentProgramSection/EndTreatmentProgramSection'
import FilesSection from './sections/FilesSection/FilesSection'
import TimeRegistrationSection from './sections/TimeRegistrationSection/TimeRegistrationSection'
import TimeRegistration2022Section from './sections/TimeRegistration2022Section/TimeRegistration2022Section'
import { useOnline } from '../../hooks/useOnline'
import { useNotificationBar } from '../../components/NotificationBar'
import InvoiceSection from './sections/InvoiceSection/InvoiceSection'
import { AcquaintanceSection } from './sections/AcquaintanceSection'
import TextField from '@material-ui/core/TextField'
import { useTranslation } from 'react-i18next'
import ContactHistorySection from './sections/ContactHistorySection/ContactHistorySection'
import { MenuContext } from './RecordPage'
import AuthenticationService from '../../services/AuthenticationService'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { Collapse } from '@material-ui/core'
import getYear from 'date-fns/getYear'
import Alert from '@material-ui/lab/Alert'

const useStyles = makeStyles((theme) => ({
  deleteIcon: {
    position: 'absolute',
    right: 25
  }
}))

const validate = (values) => {
  const errors = { patient: {} }
  const bsn = values.patient.bsn
  if (bsn && (bsn.length < 8 || bsn.length > 9)) {
    errors.patient.bsn = 'Een geldig BSN is 8 of 9 getallen lang.'
  }
  return errors
}

const CollapsibleSection = ({
  component: Component,
  title,
  anchor,
  defaultExpand = false
}) => {
  const [expanded, setExpanded] = useState(defaultExpand)
  const toggleExpand = () => setExpanded(!expanded)
  const ExpandIcon = expanded ? ExpandLessIcon : ExpandMoreIcon

  return (
    <FormSection
      action={
        <IconButton onClick={toggleExpand}>
          <ExpandIcon
            fontSize="small"
            color="disabled"
            aria-expanded={expanded}
            aria-label={expanded ? 'Collapse' : 'Expand'}
          />
        </IconButton>
      }
      anchor={anchor}
      title={title}
      render={() => (
        <Collapse in={expanded}>
          <Component />
        </Collapse>
      )}
    />
  )
}

const CollapsibleSectionWithWarning = (props) => {
  const {
    component: Component,
    warningMessage,
    title,
    anchor,
    defaultExpand = false,
    showWarning
  } = props
  const [expanded, setExpanded] = useState(defaultExpand)
  const toggleExpand = () => setExpanded(!expanded)
  const ExpandIcon = expanded ? ExpandLessIcon : ExpandMoreIcon

  return (
    <FormSection
      action={
        <IconButton onClick={toggleExpand}>
          <ExpandIcon
            fontSize="small"
            color="disabled"
            aria-expanded={expanded}
            aria-label={expanded ? 'Collapse' : 'Expand'}
          />
        </IconButton>
      }
      anchor={anchor}
      title={title}
      render={() => (
        <>
          {showWarning && <Alert severity="warning">{warningMessage}</Alert>}
          <Collapse in={expanded}>
            {Component && <Component />}
            {props.children}
          </Collapse>
        </>
      )}
    />
  )
}

export const DeletePatientDialog = ({ onConfirm, patientName }) => {
  const { t } = useTranslation('recordPage')
  const [reason, setReason] = useState()

  const handleChange = (e) => {
    setReason(e.target.value)
  }

  const handleConfirm = () => {
    onConfirm(reason)
  }

  return (
    <AlertDialog
      text={t('alertText')}
      title={t('alerTitle')}
      cancelText={t('alertCancel')}
      confirmText={t('alertConfirm')}
      onConfirm={handleConfirm}
      validationText={patientName}
      disableSubmit={!reason}
      content={
        <TextField
          required
          fullWidth
          multiline
          rows={4}
          variant={'outlined'}
          label={t('deleteReason')}
          onChange={handleChange}
          style={{ marginTop: '20px' }}
        />
      }
      render={({ onClick }) => (
        <IconButton onClick={onClick}>
          <DeleteIcon fontSize="small" color="disabled" />
        </IconButton>
      )}
    />
  )
}

// we don't want to rerender entire RecordPageContent every time selfPayer changes
// so this wrapper makes the state local to this component avoiding the unnecessary re-renders
const HealthInsuranceSectionWrapper = () => {
  const { t } = useTranslation('recordPage')
  const selfPayer = useStoreState((state) => state.user.selfPayer)

  return (
    <FormSection
      hidden={selfPayer}
      anchor="healthInsurance"
      title={t('formHealthInsurance')}
      component={HealthInsuranceSection}
    />
  )
}

const RecordPageContent = ({ match }) => {
  const { t } = useTranslation('recordPage')

  const { setUpdatedJustNow } = useStoreActions((actions) => ({
    setUpdatedJustNow: actions.user.setUpdatedJustNow
  }))
  const { setIsZPM } = useStoreActions((actions) => ({
    setIsZPM: actions.user.setIsZPM
  }))
  const { treatmentProgramId } = match.params
  const [loading, setLoading] = useState(true)
  const [treatmentProgram, setTreatmentAndFunnel] = useState()
  const [funnel, setFunnel] = useState()
  const classes = useStyles()
  const { showMessage, NotificationBar } = useNotificationBar()
  const isOnline = useOnline()
  const { hideMenuItem } = useContext(MenuContext)
  const canRemoveRecord =
    AuthenticationService.isBackOffice() ||
    AuthenticationService.isAdministrator() ||
    AuthenticationService.isSuperUser()

  // Zorgprestatiemodel (ZPM) is the health care system from 2022 onwards.
  const isZPM =
    !treatmentProgram?.startDate ||
    getYear(new Date(treatmentProgram?.startDate)) >= 2022

  setIsZPM(isZPM)

  const save = async (values, errors) => {
    console.log('Saving', values)
    console.log('Errors', errors)

    if (!isOnline) {
      showMessage(t('offlineMessage'), 'error')
      return
    }

    try {
      // eslint-disable-next-line no-prototype-builtins
      if (values.hasOwnProperty('funnel')) {
        await client.updateFunnel(treatmentProgram.funnel.id, values.funnel)
      } else {
        await client.saveTreatmentProgram(treatmentProgramId, values)
      }
      setUpdatedJustNow(true)
      setTimeout(() => setUpdatedJustNow(false), 5000)
    } catch (e) {
      // If there's no response, the request failed
      if (!e.response) {
        showMessage(t('noResponseMessage'), 'error')
        return
      }

      // If there's a response, but it contains an error, something went wrong in the backend
      switch (e.response.status) {
        case 409:
          showMessage(t('error409Message'), 'error')
          break
        default:
          showMessage(t('errorMessage'), 'error')
          break
      }
    }
  }

  const handleRemovePatient = async (reason) => {
    await client.removeFunnel(funnel.id)
    await client.removePatient({ id: treatmentProgram.patient.id, reason })
    window.location.href = '/'
  }

  useEffectAsync(async () => {
    setLoading(true)
    let assignedFunnel
    try {
      const treatment = await client.getTreatmentProgramById(treatmentProgramId)

      if (treatment) {
        assignedFunnel = await client.findFunnelByTreatmentProgramId(
          treatmentProgramId
        )
        treatment.funnel = assignedFunnel
        setFunnel(assignedFunnel)
      }
      setTreatmentAndFunnel(treatment)
    } catch (e) {
      // No funnel for this client.
      hideMenuItem('contactHistory')
    }
    setLoading(false)
  }, [match.params.patientId])

  if (loading) return <PageLoadingIndicator />

  if (!treatmentProgram)
    return (
      <Typography
        component="h1"
        variant="h6"
        align="left"
        color="textSecondary"
        gutterBottom
        style={{ marginLeft: 15 }}
      >
        {t('treatmentProgramNotFound')}
      </Typography>
    )

  return (
    <Form
      initialValues={treatmentProgram}
      onSubmit={save}
      subscription={{ submitting: true, pristine: true, invalid: true }}
      validate={validate}
      validateOnBlur
      mutators={{
        ...arrayMutators,
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value)
        }
      }}
      render={({ handleSubmit, form: { getState, mutators } }) => {
        const patient = getState().values.patient
        const patientName = [patient.firstName, patient.infix, patient.lastName]
          .filter((part) => !!part) // ignore falsy parts (empty string, null)
          .map((part) => part.trim())
          .join(' ')
        return (
          <div>
            <Grid container direction="row">
              <Grid item>
                <Typography
                  component="h1"
                  variant="h6"
                  align="left"
                  color="textSecondary"
                  gutterBottom
                  style={{ marginLeft: 15 }}
                >
                  {patientName}
                </Typography>
              </Grid>
              {canRemoveRecord && (
                <Grid item>
                  <div className={classes.deleteIcon}>
                    <DeletePatientDialog
                      patientName={patientName}
                      onConfirm={handleRemovePatient}
                    />
                  </div>
                </Grid>
              )}
            </Grid>
            <form onSubmit={handleSubmit} autoComplete="off">
              <AutoSave
                debounce={1000}
                save={(values) => save(values, getState().errors)}
              />
              <FormActions />
              <div className="form">
                <FormSection
                  hidden={!funnel}
                  anchor="treatmentStatus"
                  title={t('formTreatmentStatus')}
                  render={() => <TreatmentStatusSection funnel={funnel} />}
                />
                <FormSection
                  anchor="treatmentProgram"
                  title={t('formTreatmentProgram')}
                  component={TreatmentProgramSection}
                />
                <FormSection
                  anchor="patientDetails"
                  title={t('formPatientDetails')}
                  component={PatientDetailsSection}
                />
                <HealthInsuranceSectionWrapper />
                <FormSection
                  anchor="therapist"
                  title={t('formTherapist')}
                  component={TherapistSection}
                />
                <FormSection
                  anchor="referrer"
                  title={t('formReferrer')}
                  component={ReferrerSection}
                />
                <FormSection
                  anchor="startTreatmentProgram"
                  title={t('formStartTreatment')}
                  component={StartTreatmentProgramSection}
                  initialPrivacyStatementFile={
                    getState().values.privacyStatementFile
                  }
                  initialTreatmentAgreementFile={
                    getState().values.treatmentAgreementFile
                  }
                  initialHoNOSResultFile={getState().values.hoNOSResultFile}
                />
                <CollapsibleSection
                  defaultExpand={!isZPM}
                  anchor="timeRegistration"
                  title={t('formTimeRegistration')}
                  component={TimeRegistrationSection}
                />
                <CollapsibleSection
                  defaultExpand={isZPM}
                  anchor="timeRegistration"
                  title={t('formTimeRegistration2022')}
                  component={TimeRegistration2022Section}
                />

                <FormSection
                  anchor="acquaintanceNote"
                  title={t('formAcquintanceNote')}
                  component={AcquaintanceSection}
                />
                <FormSection
                  anchor="intakeNote"
                  title={t('formIntakeNote')}
                  name="intakeNote"
                  component={NotesSection}
                />
                <FormSection
                  anchor="treatmentPlan"
                  title={t('formTreatmentPlan')}
                  component={TreatmentPlanSection}
                  name="treatmentPlan"
                />
                <SessionNotesSection />
                <FormSection
                  anchor="remarks"
                  title={t('formRemarks')}
                  component={NotesSection}
                  name="remarks"
                />
                <FormSection
                  anchor="endTreatmentProgram"
                  title={t('formEndTreatment')}
                  component={EndTreatmentProgramSection}
                />
                <FormSection
                  anchor="invoice"
                  title={t('formInvoice')}
                  render={() => (
                    <InvoiceSection
                      zpm={isZPM}
                      treatmentType={treatmentProgram?.treatmentType}
                    />
                  )}
                />
                <FormSection
                  anchor="files"
                  title={t('formFiles')}
                  component={FilesSection}
                />
                {funnel ? (
                  <FormSection
                    anchor="contactHistory"
                    title={t('contactHistory')}
                    funnel={funnel}
                    component={ContactHistorySection}
                  />
                ) : null}
              </div>
            </form>
            {NotificationBar}
          </div>
        )
      }}
    />
  )
}

export default withRouter(RecordPageContent)
