import { Redirect, Route, Switch } from 'react-router-dom'
import { RecordPage } from './RecordPage'
import { NewUserPage } from './NewUserPage'
import { SettingsPage } from './SettingsPage'
import { WhitelistPage } from './WhitelistPage'
import { PatientDetailsPage } from './PatientDetailsPage'
import { BackOfficePage } from './BackOfficePage'
import AuthenticationService from '../services/AuthenticationService'
import { PatientSelectPage } from './PatientSelectPage'
import { FourOhFour } from './FourOhFourPage'
import { StatisticsPage } from './StatisticsPage'
import UserListPage from './AdminToolsPage/UserListPage'
import PracticeListPage from './AdminToolsPage/PracticeListPage'
import LocationListPage from './AdminToolsPage/LocationListPage'
import AdminToolsPage from './AdminToolsPage'
import PsychologistListPage from './AdminToolsPage/PsychologistListPage'
import InsurerListPage from './AdminToolsPage/InsurerListPage'

const PageRoutes = () => {
  return (
    <Switch>
      <Route exact path="/clienten" component={PatientSelectPage} />
      <Route
        exact
        path="/wachtlijst/:funnelId"
        component={PatientDetailsPage}
      />
      <Route
        exact
        path="/clienten/:patientId/trajecten/:treatmentProgramId"
        component={RecordPage}
      />
      <Route exact path="/instellingen" component={SettingsPage} />
      <Route exact path="/wachtlijst" component={BackOfficePage} />
      <Route exact path="/gebruikers/toevoegen" component={NewUserPage} />
      <Route exact path="/whitelist" component={WhitelistPage} />
      <Route exact path="/statistieken" component={StatisticsPage} />
      <Route exact path="/userlist" component={UserListPage} />
      <Route exact path="/practicelist" component={PracticeListPage} />
      <Route exact path="/locationlist" component={LocationListPage} />
      <Route exact path="/admin-tools" component={AdminToolsPage} />
      <Route exact path="/psychologistlist" component={PsychologistListPage} />
      <Route exact path="/insurerlist" component={InsurerListPage} />
      <Route
        exact
        path="/"
        render={({ location }) =>
          AuthenticationService.isBackOffice() ? (
            <Redirect
              to={{
                pathname: '/wachtlijst',
                state: { from: location }
              }}
            />
          ) : (
            <Redirect
              to={{
                pathname: '/clienten',
                state: { from: location }
              }}
            />
          )
        }
      />
      <Route component={FourOhFour} />
    </Switch>
  )
}

export default PageRoutes
