import BackOfficePageMenu from './BackOfficePageMenu'
import Page from '../../layout/Page'
import BackOfficePageContent from './BackOfficePageContent'
import { PageLoadingIndicator } from '../../components/PageLoadingIndicator'
import { useState } from 'react'
import useEffectAsync from '../../hooks/useEffectAsync'
import { client } from '../../client'
import { FunnelStatus } from '../../types'

const BackOfficePage = (): JSX.Element => {
  const [statuses, setStatuses] = useState<Array<FunnelStatus>>([])

  useEffectAsync(async () => {
    const fetchedStatuses = await client.getFunnelStatusOptions()

    const preferences = loadPreferences()

    // Sort statuses by id
    setStatuses(
      fetchedStatuses
        .sort((a, b) => a.id - b.id)
        .map((status) => {
          status.isVisible = status.isVisibleByDefault
          if (status.id in preferences) {
            status.isVisible = preferences[status.id]
          }
          return status
        })
    )
  }, [])

  // Toggle visibility of status block with name === statusName
  const toggleStatus = (statusName: string) => {
    const index = statuses.findIndex((status) => status.name === statusName)
    const newStatuses = Array.from(statuses)
    newStatuses[index].isVisible = !newStatuses[index].isVisible
    updatePreferences(newStatuses[index].id, !!newStatuses[index].isVisible)
    setStatuses(newStatuses)
  }

  return statuses ? (
    <Page
      menu={<BackOfficePageMenu statuses={statuses} toggle={toggleStatus} />}
      content={<BackOfficePageContent statuses={statuses} />}
    />
  ) : (
    <PageLoadingIndicator />
  )
}

const loadPreferences = (): Record<number, boolean> => {
  return JSON.parse(localStorage.getItem('funnel-statuses-preferences') || '{}')
}

const savePreferences = (preferenes: Record<number, boolean>): void => {
  localStorage.setItem(
    'funnel-statuses-preferences',
    JSON.stringify(preferenes)
  )
}

const updatePreferences = (id: number, visible: boolean): void => {
  const current = loadPreferences()
  savePreferences({ ...current, [id]: visible })
}

export default BackOfficePage
