import { CircularProgress } from '@material-ui/core'
import { createContext, PropsWithChildren, useContext } from 'react'
import { Options, useOptions } from '../../../../hooks/useOptions'

const OptionsContext = createContext<Options | null>(null)

type OptionsContextProviderProps = PropsWithChildren<unknown>

export const OptionsContextProvider = ({
  children
}: OptionsContextProviderProps): JSX.Element => {
  const options = useOptions(false) // we are inside 2022 section so it's not legacy
  if (options.loading) return <CircularProgress />
  return (
    <OptionsContext.Provider value={options}>
      {children}
    </OptionsContext.Provider>
  )
}

export const useOptionsContext = (): Options => {
  const options = useContext(OptionsContext)
  if (!options) {
    throw new Error(
      'useOptionsContext must be used within OptionsContextProvider'
    )
  }
  return options
}
