import { useEffect, useMemo } from 'react'
// import { TextField } from 'mui-rff'
import { TextField } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { getYearFromDate } from '../../../../utils/getYearFromDate'
import { useOptionsContext } from './optionsContext'
import { useFieldValue } from '../../../../hooks/useFieldValue'
import * as types from '../../../../types'
import { useField } from 'react-final-form'

const uninsuredCodes = [
  'uninsured15',
  'uninsured30',
  'uninsured45',
  'uninsured60',
  'uninsured75',
  'uninsured90',
  'uninsured120',
  'OV0012'
]

const noShowCodes = ['no_show', 'coulance']

// TODO: wtf is this
export const filterPrices = (
  price: types.ConsultationPrice,
  activity: types.Activity2022,
  treatmentType: types.TreatmentType
): boolean => {
  if (activity.consultationType?.id === 3) {
    // Intercollegiaal overleg
    return (
      price.duration?.id === activity.duration?.id &&
      price.consultationType?.id === activity.consultationType?.id &&
      price.year === getYearFromDate(activity.date)
    )
  } else if (activity.consultationType?.id === 4) {
    // No-show
    return (
      noShowCodes.includes(price.code) &&
      price.duration?.id === activity.duration?.id
    )
  } else if (treatmentType.id === 2) {
    // Onverzekerde zorg
    return (
      uninsuredCodes.includes(price.code) &&
      price.duration?.id === activity.duration?.id &&
      price.year === getYearFromDate(activity.date)
    )
  } else if (uninsuredCodes.includes(price.code)) {
    // hotfix 31.01.23
    // todo: find a proper way to remove prices of uninsured care
    return false
  }

  return (
    price.duration?.id === activity.duration?.id &&
    price.consultationType?.id === activity.consultationType?.id &&
    price.year === getYearFromDate(activity.date)
  )
}

const findPrice = (
  consultationPrices: types.ConsultationPrice[],
  activity: types.Activity2022,
  treatmentType: types.TreatmentType
): types.ConsultationPrice | undefined =>
  consultationPrices.find((price) =>
    filterPrices(price, activity, treatmentType)
  )

export const ConsultationPriceField = ({
  rootName
}: {
  rootName: string
}): JSX.Element => {
  const { t } = useTranslation('timeRegistrationSection')

  const activity = useFieldValue(rootName)
  const treatmentType = useFieldValue('treatmentType')
  const { consultationPrices } = useOptionsContext()

  const {
    input: { onChange }
  } = useField(`${rootName}.consultationPrice.id`)

  // find price the matches the selected activity
  // (pricing is based on activity date, duration, and type)
  const price = useMemo(
    () => findPrice(consultationPrices, activity, treatmentType),
    [consultationPrices, activity, treatmentType]
  )

  // update value in the form so it can be submitted
  useEffect(() => onChange(price?.id), [price, onChange])

  // this is not a real form field, it's just for display
  // so we don't need the name prop
  return (
    <TextField
      value={price?.value || '0'}
      label={t('consultationPrice')}
      InputProps={{ readOnly: true }}
    />
  )
}
