import {
  Checkbox,
  DatePicker,
  Field,
  FileUploadCheckbox,
  Select
} from '../../../../react-final-form-wrappers'
import FormRow from '../../FormRow'
import { client } from '../../../../client'
import { TextField } from 'mui-rff'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'

const EndTreatmentProgramSection = () => {
  const { t } = useTranslation('endTreatmentSection')

  const {
    input: { value: programId }
  } = useField('id')
  const {
    input: { value: isLegacy }
  } = useField('isLegacy')

  const handleSubmit = async (file, type) => {
    const response = await client.uploadFile(file, programId, type)
    return response.data
  }

  const handleDelete = async (file) => {
    await client.removeFile(file.id)
  }

  return (
    <>
      {isLegacy && (
        <FormRow>
          <Select
            style={{ width: 500 }}
            label={t('performanceLabel')}
            getOptions={async () => client.getProductOptions()}
            getOptionLabel={(i) => i.name}
            getOptionValue={(i) => i.id}
            name="productEnd.id"
          />
        </FormRow>
      )}
      {/* 
         NOTE: Client requested to remove this field but I suspect they will want it back in the future
               so for now I'm just leaving it commented out
      */}
      {/* <FormRow> */}
      {/*   <Field */}
      {/*     name="endSq48Sent" */}
      {/*     label={`SQ48 ${t('endSent')}`} */}
      {/*     type="checkbox" */}
      {/*     component={Checkbox} */}
      {/*   /> */}
      {/* </FormRow> */}
      <FormRow>
        <Field
          name="endIsiSent"
          label={`ISI ${t('endSent')}`}
          type="checkbox"
          component={Checkbox}
        />
      </FormRow>
      <FormRow>
        <Field
          name="cqiSent"
          label={`Evaluatie ${t('sent')}`}
          type="checkbox"
          component={Checkbox}
        />
      </FormRow>
      <FormRow>
        <TextField
          name="endIsiScore"
          label={`ISI-score (${t('endMeasurement')})`}
        />
      </FormRow>
      <FormRow>
        <Field
          style={{ width: 300 }}
          name="endDate"
          label={t('endDateLabel')}
          component={DatePicker}
        />
      </FormRow>
      <FormRow>
        <Select
          style={{ width: 300 }}
          label={t('lastStep')}
          getOptions={async () =>
            isLegacy
              ? client.getClosingReasonOptions()
              : client.getClosingReasonOptions(false)
          }
          getOptionLabel={(i) => i.name}
          getOptionValue={(i) => i.id}
          name="closingReason.id"
        />
      </FormRow>

      <FormRow>
        <Field
          label={t('endLetter')}
          name="endLetterFile"
          onSubmit={(file) => handleSubmit(file, 'endLetter')}
          onDelete={handleDelete}
          component={FileUploadCheckbox}
        />
      </FormRow>
    </>
  )
}

export default EndTreatmentProgramSection
