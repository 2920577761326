import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import DragableFunnelTable from "./DragableFunnelTable";
import React from "react";
import {Funnel} from "../../../../types";

interface IStatusBlockProps {
    funnels: Array<Funnel>;
    name: any;
    anchor: any;
    onStatusChange: any;
    forceUpdate: ()=>void
}

const StatusBlock: React.FunctionComponent<IStatusBlockProps> = ({ name, anchor, funnels, onStatusChange, ...props }) => (
    <div>
        <a href={`#${anchor}`} id={anchor}>
            {/* why? */}
            <div />
        </a>
        <Card style={{ marginBottom: 20 }}>
            <CardContent>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {name}
                </Typography>
                <DragableFunnelTable
                    funnels={funnels}
                    onStatusChange={onStatusChange}
                    forceUpdate={props.forceUpdate}
                />
            </CardContent>
        </Card>
    </div>
);

export default StatusBlock;