import { useState } from 'react'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import EyeOffIcon from 'mdi-material-ui/EyeOff'
import EyeIcon from 'mdi-material-ui/Eye'
import * as PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

const PasswordField = ({
  classes,
  buttonDisabled,
  visible: visibleProp, // eslint-disable-line
  ...other
}) => {
  const [visible, setVisible] = useState(false)

  /**
   * Toogles the visibility the password.
   * @public
   */
  const toggleVisibility = () => setVisible(!visible)

  const handleButtonMouseDown = (e) => {
    e.preventDefault()
  }

  return (
    <TextField
      {...other}
      type={visible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={toggleVisibility}
              onMouseDown={handleButtonMouseDown}
              disabled={other.disabled || buttonDisabled}
            >
              {visible ? <EyeIcon /> : <EyeOffIcon />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

PasswordField.defaultProps = {
  buttonDisabled: false,
  visible: false
}

PasswordField.propTypes = {
  ...Input.propTypes,
  buttonDisabled: PropTypes.bool,
  visible: PropTypes.bool
}

export default PasswordField
