import { Field as FIELD } from 'react-final-form'

/* react-final-form's <Field /> content sets empty values to undefined.
 * To communicate to the backend that we want to unset the field, we send
 * a null value instead.
 *
 * See also: https://github.com/final-form/react-final-form/issues/130
 */
const Field = ({ defaultValue = null, value, ...props }) => (
  <FIELD parse={(value) => (value === '' ? defaultValue : value)} {...props} />
)
export default Field
