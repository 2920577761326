import en from './languages/en.json'
import nl from './languages/nl.json'

const resources = {
  en: {
    common: en,
    ...en
  },
  nl: {
    common: nl,
    ...nl
  }
}

export default resources
