import TreatmentsPerMonthSplitByTherapist from './chartStats/GetTreatmentsPerMonthSplitTherapist'
import GetTreatmentsPerMonth from './chartStats/GetTreatmentsPerMonth'
import SessionsPerTherapist from './chartStats/SessionsPerTherapist'
import CompletedTreatmentPrograms from './chartStats/CompletedTreatmentPrograms'
import Caseload2 from './chartStats/Caseload2'
import Caseload from './chartStats/Caseload'
import ClientRegistrations from './chartStats/ClientRegistrations'
import DiagnosesFilteredByYear from './chartStats/DiagnosesFilteredByYear'
import Diagnoses from './chartStats/Diagnoses'
import GendersFilteredByYear from './chartStats/GendersFilteredByYear'
import Genders from './chartStats/Genders'
import Age from './chartStats/Age'
import Postcodes from './chartStats/Postcodes'
import SessionsPerPractice from './chartStats/SessionsPerPractice'
import SessionsPerWeek from './chartStats/SessionsPerWeek'
import { useTheme } from '@material-ui/core/styles'
import ConsultationPriceUsage from './consultationPriceUsage'

export const randomColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`

export const useThemeColor = (color = 'primary') => {
  const theme = useTheme()
  return theme.palette[color].main
}

export const getMinMax = (data) => {
  let min = 'auto'
  let max = 'auto'
  if (data.data && data.data.length) {
    // Find the key that holds the data value
    let key
    for (const [objKey] of Object.entries(data.data[0])) {
      if (objKey !== 'name') key = objKey
    }
    min = Math.min.apply(
      Math,
      data.data.map(function (f: any) {
        return f[key]
      })
    )
    max = Math.max.apply(
      Math,
      data.data.map(function (f: any) {
        return f[key]
      })
    )
  }
  return [min, max]
}

const StatisticsPage = () => (
  <>
    <GetTreatmentsPerMonth />
    <TreatmentsPerMonthSplitByTherapist />
    <SessionsPerTherapist />
    <CompletedTreatmentPrograms />
    <Caseload2 />
    <Caseload />
    <ClientRegistrations />
    <DiagnosesFilteredByYear />
    <Diagnoses />
    <GendersFilteredByYear />
    <Genders />
    <Age />
    <Postcodes />
    <SessionsPerPractice />
    <SessionsPerWeek />
    <ConsultationPriceUsage />
  </>
)

export default StatisticsPage
