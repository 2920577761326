import Field from './Field'
import Select from './Select'
import SingleSelect from './SingleSelect'
import { DateCheckbox as DATE_CHECKBOX } from '../components/DateCheckbox'
import { SelectCheckbox as SELECT_CHECKBOX } from '../components/SelectCheckbox'
import { RichTextEditor as RICH_TEXT_EDITOR } from '../components/RichTextEditor'
import { default as SWITCH } from '@material-ui/core/Switch'
import { default as CHECKBOX } from '@material-ui/core/Checkbox'
import { default as ASYNC } from 'react-select/async'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { FileUploadCheckbox as FILE_UPLOAD_CHECKBOX } from '../components/FileUploadCheckbox'
import { FileUploadButton as FILE_UPLOAD_BUTTON } from '../components/FileUploadButton'
import { withReactFinalForm } from './withReactFinalForm'
import { withReactFinalFormToggle } from './withReactFinalFormToggle'
import { default as AUTOCOMPLETE } from '../react-final-form-wrappers/Autocomplete'

// Simple form components
export const DateCheckbox = withReactFinalForm(DATE_CHECKBOX)
export const SelectCheckbox = withReactFinalForm(SELECT_CHECKBOX)
export const FileUploadCheckbox = withReactFinalForm(FILE_UPLOAD_CHECKBOX)
export const Async = withReactFinalForm(ASYNC)
export const RichTextEditor = withReactFinalForm(RICH_TEXT_EDITOR)
export const DatePicker = withReactFinalForm(KeyboardDatePicker, {
  autoOk: true,
  variant: 'inline',
  format: 'dd-MM-yyyy',
  invalidDateMessage: 'Ongeldige datum (gebruik dag-maand-jaar)'
})

export const FileUploadButton = withReactFinalForm(FILE_UPLOAD_BUTTON)
export const Autocomplete = withReactFinalForm(AUTOCOMPLETE)

// Toggles
export const Switch = withReactFinalFormToggle(SWITCH)
export const Checkbox = withReactFinalFormToggle(CHECKBOX)

export { Field, Select, SingleSelect }
