import { useEffect, useState } from 'react'
import { EditorState } from 'draft-js'
import { Editor, EditorProps } from 'react-draft-wysiwyg'
import './react-draft-wysiwyg.css'
import { stateFromMarkdown } from 'draft-js-import-markdown'
import { stateToMarkdown } from 'draft-js-export-markdown'

const toolbar = {
  options: ['inline', 'list', 'link', 'history'],
  inline: {
    options: ['bold', 'italic', 'underline', 'strikethrough']
  }
}

type RichTextEditorProps = EditorProps & {
  initial: string | null
  value: string | null
  onChange: (value: string) => void
}

const RichTextEditor = ({
  initial,
  value,
  onChange,
  ...rest
}: RichTextEditorProps): JSX.Element => {
  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(stateFromMarkdown(initial || ''))
  )
  const [markdownCache, setMarkdownCache] = useState(initial || '')

  useEffect(() => {
    if (value !== null && value !== undefined && value !== markdownCache) {
      setEditorState(EditorState.createWithContent(stateFromMarkdown(value)))
      setMarkdownCache(value)
    }
  }, [value, markdownCache])

  const handleEditorStateChange = (newState: EditorState) => {
    const markdown = stateToMarkdown(newState.getCurrentContent())
    onChange(markdown)
    setMarkdownCache(markdown)
    setEditorState(newState)
  }

  return (
    <Editor
      {...rest}
      toolbar={toolbar}
      editorState={editorState}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      onEditorStateChange={handleEditorStateChange}
    />
  )
}

export default RichTextEditor
