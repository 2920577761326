import { createRef, useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import { makeStyles } from '@material-ui/core/styles'
import DisorderTable from './DisorderTable'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  selectedOption: {
    marginRight: 50
  }
}))

const DisorderDialog = ({ onSubmit, open, setOpen }) => {
  const { t } = useTranslation('disorderSection')
  const [disorders, setDisorders] = useState([])
  const classes = useStyles()
  const tableRef = createRef()

  const clear = () => {
    setDisorders([])

    // NOTE: we need to explicitly deselect everything, because the table is not
    // re-rendered when clicking on 'Cancel'.
    tableRef.current.onAllSelected(false)
  }

  const close = () => {
    setOpen(false)
  }

  const handleSubmit = () => {
    onSubmit(disorders)
    clear()
    close()
  }

  const handleChange = (values) => {
    setDisorders(values)
  }

  const handleCancel = () => {
    clear()
    close()
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleCancel}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{t('selectDiagnose')}</DialogTitle>
      <DialogContent>
        <DisorderTable onChange={handleChange} tableRef={tableRef} />
      </DialogContent>
      <DialogActions>
        <Typography
          className={classes.selectedOption}
          component="p"
          variant="body1"
        >
          {disorders.length > 0 &&
            `${t('selectedDiagnoses')} ` +
              disorders.map((el) => el.value).join(', ')}
        </Typography>
        <Button onClick={handleCancel} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DisorderDialog
