import {useFieldArray} from "react-final-form-arrays";
import React, {useEffect} from "react";
import InvoiceRow from "./InvoiceRow";

const InvoiceRows = ({ treatmentTypeId }) => {
    const { fields } = useFieldArray('invoiceRows');

    // Observe fields and add a new row if the last row is touched
    useEffect(() => {
        const isRowTouched = ({ date, description, amount, vatPercentage }) => {
            return date || description || amount || vatPercentage;
        };

        const getLastRowValues = (): any | never => {
            if (!fields.length) {
                throw new Error('Couldn\'t get last row value - empty array');
            }
            return fields.value[fields.length - 1];
        };

        const isLastRowTouched = () => {
            return fields.length && isRowTouched(getLastRowValues());
        };

        if (isLastRowTouched()) {
            fields.push({});
        }
    }, [fields]);

    const handleDeleteRow = (index) => {
        // Always keep one row, otherwise no new ones can be added.
        // @ts-ignore
        if (fields.length > 1) {
            fields.remove(index);
        }
    };

    return fields.map((name, index) => (
        <>
            {/*// @ts-ignore*/}
            <InvoiceRow
                key={index}
                name={name}
                onDelete={() => handleDeleteRow(index)}
                treatmentTypeId={treatmentTypeId}
            />
        </>
    ));
};

export default InvoiceRows;
