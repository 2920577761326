import MaterialTable from 'material-table'
import data from './tree.json'
import icons from '../../../../utils/icons'
import Paper from '@material-ui/core/Paper'
import { useTranslation } from 'react-i18next'

const DisorderTable = ({ onChange, ...rest }) => {
  const { t } = useTranslation('disorderSection')

  const isLeaf = (rowData) =>
    data.find((el) => el.parentId === rowData.id) === undefined

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }

  return (
    <MaterialTable
      {...rest}
      title="DSM-5"
      data={data}
      columns={[
        {
          title: t('diagnose'),
          field: 'value',
          sorting: true,
          defaultSort: 'asc'
        },
        { title: 'Code', field: 'code' }
      ]}
      icons={icons}
      parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
      options={{
        selection: true,
        paging: false,
        sorting: true,
        search: true,
        showSelectAllCheckbox: false,
        selectionProps: (rowData) => ({
          disabled: !isLeaf(rowData),
          color: 'primary'
        })
      }}
      onSelectionChange={(rows) =>
        onChange(
          rows.filter(onlyUnique).map(({ id, value }) => ({ id, value }))
        )
      }
      components={{
        Container: (props) => <Paper {...props} elevation={0} />
      }}
      localization={{
        toolbar: {
          searchPlaceholder: t('searchPlaceholder')
        }
      }}
    />
  )
}

export default DisorderTable
