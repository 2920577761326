import { useState } from 'react'
import useEffectAsync from '../../../../hooks/useEffectAsync'
import { LoadingIndicator } from '../../../../components/LoadingIndicator'
import format from 'date-fns/format'
import nlLocale from 'date-fns/locale/nl'
import { dateTimeToDate } from '../../../../utils/dates'
import Typography from '@material-ui/core/Typography'
import { client } from '../../../../client'
import { useTranslation } from 'react-i18next'

const LetterTemplate = ({ programId }) => {
  const { t } = useTranslation('letterTemplate')
  const [state, setState] = useState()

  useEffectAsync(async () => {
    const program = await client.getTreatmentProgramById(programId)

    const {
      firstName: psychologistFirstName,
      infix: psychologistInfix,
      lastName: psychologistLastName
    } = program.primaryPsychologist.user
    const {
      infix: patientInfix,
      lastName: patientLastName,
      birthDate: patientBirthDate,
      gender: { salutationShort: salutation },
      street,
      residence,
      houseNumber,
      houseNumberAddition
    } = program.patient
    setState({
      date: format(new Date(), 'PPP', {
        locale: nlLocale
      }),
      startDate: dateTimeToDate(program?.startDate),
      psychologist: {
        name: [
          psychologistFirstName,
          psychologistInfix,
          psychologistLastName
        ].join(' '),
        phoneNumber: program?.primaryPsychologist?.phoneNumber,
        email: program?.primaryPsychologist?.user?.email
      },
      practiceName: program?.practice?.name,
      patient: {
        name: [salutation.toLowerCase(), patientInfix, patientLastName].join(
          ' '
        ),
        birthDate: dateTimeToDate(patientBirthDate),
        address: `${street} ${houseNumber} ${houseNumberAddition || ''}`,
        residence
      }
    })
  }, [])

  if (!state) {
    return <LoadingIndicator />
  }

  return (
    <div>
      <Typography paragraph variant="body2" align="right">
        {state.date}
      </Typography>
      <Typography paragraph variant="body2">
        {t('intro')}
      </Typography>
      <Typography paragraph variant="body2">
        {t('letterContent', {
          name: state.patient.name,
          birthDate: state.patient.birthDate,
          address: state.patient.address,
          residence: state.patient.residence,
          startDate: state.startDate
        })}
      </Typography>
      <Typography paragraph variant="body2">
        {t('sincerely')}
      </Typography>
      <Typography variant="body2">{state.psychologist.name}</Typography>
      <Typography variant="body2">
        {t('doctorTitle')} {state.practiceName}
      </Typography>
      <Typography variant="body2">{state.psychologist.phoneNumber}</Typography>
      <Typography variant="body2">{state.psychologist.email}</Typography>
    </div>
  )
}

export default LetterTemplate
