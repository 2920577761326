import { Select } from 'mui-rff'
import { useEffect, useState } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import useEffectAsync from '../hooks/useEffectAsync'
import to from '../utils/to'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { useTranslation } from 'react-i18next'

const SelectWrapper = (props) => {
  const { t } = useTranslation('reactFinalForm')
  const {
    getOptionValue,
    getOptionLabel,
    getOptions, // async
    options: propOptions,
    name,
    label,
    optionsFilter,
    MenuItemProps,
    ...rest
  } = props

  const [options, setOptions] = useState(propOptions || [])
  const [loading, setLoading] = useState(
    (propOptions && propOptions.length === 0) || options?.length === 0
  )
  const [error, setError] = useState(null)

  useEffect(() => {
    setOptions(propOptions)
    if (propOptions?.length > 0) setLoading(false)
  }, [propOptions])

  useEffectAsync(async () => {
    if (getOptions) {
      const [options, error] = await to(getOptions())
      if (error) {
        setError(error)
      }
      setOptions(options)
      setLoading(false)
    }
  }, [])

  if (loading) {
    return <LoadingIndicator />
  } else if (error) {
    return (
      <div>
        {t('errorOccurred')} {error}
      </div>
    )
  } else if (options.length > 0) {
    const renderOptions = (options) => {
      return (
        <Select {...rest} name={name} label={label}>
          {options.map((i) => {
            const props = MenuItemProps && MenuItemProps(i)
            return (
              <MenuItem
                key={getOptionValue(i)}
                value={getOptionValue(i)}
                {...props}
              >
                {getOptionLabel(i)}
              </MenuItem>
            )
          })}
        </Select>
      )
    }

    if (optionsFilter) {
      const filteredOptions = optionsFilter(options)
      return renderOptions(filteredOptions)
    }

    return renderOptions(options)
  }
  return null
}

export default SelectWrapper
