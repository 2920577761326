import { useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import NotificationBar from './NotificationBar'

const defaults = {
  vertical: 'bottom',
  horizontal: 'left',
  autoHideDuration: 5000
}

/**
 * Creates a NotificationBar and a function to show messages in it.
 * @param vertical vertical: enum: 'top' | 'bottom' (default: 'bottom')
 * @param horizontal enum: 'left' | 'center' | 'right (default: 'left')
 * @param autoHideDuration hide after numner of milliseconds (default: 5000)
 * @returns {{showMessage: showMessage, NotificationBar: *}}
 */
const useNotificationBar = (props) => {
  const { vertical, horizontal, autoHideDuration } = { ...defaults, ...props }
  const [open, setOpen] = useState(false)
  const [variant, setVariant] = useState('success')
  const [message, setMessage] = useState('')

  const handleClose = () => {
    setOpen(false)
  }

  const showMessage = (message, variant = 'info') => {
    setMessage(message)
    setVariant(variant)
    setOpen(true) // closing is handled automatically by the Snackbar
  }

  const notificationBar = (
    <Snackbar
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <NotificationBar
        onClose={handleClose}
        variant={variant}
        message={message}
      />
    </Snackbar>
  )

  return {
    showMessage: showMessage,
    NotificationBar: notificationBar
  }
}

export default useNotificationBar
