import React from 'react'

const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
  const length = 14
  const trimmedString =
    payload.value.length > length
      ? payload.value.substring(0, length - 3) + '...'
      : payload.value
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        fontSize=".8em"
        x={0}
        y={0}
        dy={16}
        textAnchor="end"
        fill="#666"
        transform="rotate(-35)"
      >
        {trimmedString}
      </text>
    </g>
  )
}

export default CustomizedAxisTick
