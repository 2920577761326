import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import useEffectAsync from '../../../hooks/useEffectAsync'
import CommonLineBarChart from '../charts/CommonLineBarChart'
import { client } from '../../../client'
import ChartNoData from '../charts/ChartNoData'
import { useThemeColor } from '../StatisticsPageContent'

const SessionsPerTherapist = () => {
  const { t } = useTranslation('statisticsPage')
  const [data, setData] = useState([])
  const themeColor = useThemeColor()

  useEffectAsync(async () => {
    setData(await client.getSessionsPerTherapist())
  }, [])

  const title = t('getSessionsPerTherapist')
  if (!data.length) return <ChartNoData title={title} />

  const mapData = data.map((item) => {
    return {
      name: `${item.voornaam} : ${item.praktijk}`,
      count: item.count
    }
  })

  const resData = {
    rawData: data,
    data: mapData,
    bars: [{ name: 'count', color: themeColor }],
    title
  }
  return <CommonLineBarChart data={resData} />
}

export default SessionsPerTherapist
