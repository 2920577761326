import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { withRouter } from 'react-router-dom'
import { Typography } from '@material-ui/core'

const TreatmentStatusSelector = ({
  options,
  label = '',
  value = 5,
  onChange
}) => {
  return (
    <FormControl
      style={{
        width: 300
      }}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value}
        onChange={onChange}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          getContentAnchorEl: null
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            <Typography variant="body2" style={{ whiteSpace: 'normal' }}>
              {option.name}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default withRouter(TreatmentStatusSelector)
