import React, { useState } from 'react'
import { useField } from 'react-final-form'
import { FieldArray } from 'react-final-form-arrays'
import { useFieldValue } from '../../../../hooks/useFieldValue'
import Alert from '@material-ui/lab/Alert'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { invoiceTypes } from './helpers'
import InvoiceDialog from './InvoiceDialog'
import InvoiceActivity2022Row from './InvoiceActivity2022Row'
import { useOptions } from '../../../../hooks/useOptions'
import InvoiceList from './InvoiceList'

const useMissingFields = (zpm: boolean) => {
  const { t } = useTranslation('invoiceSection')
  const treatmentId = useFieldValue('treatmentType.id')
  const isInsured = treatmentId === 1 || treatmentId === 5

  const patientPostalCode = useFieldValue('patient.postalCode')
  const patientResidence = useFieldValue('patient.residence')
  const practice = useFieldValue('practice')
  const treatmentType = useFieldValue('treatmentType')
  const primaryPsychologist = useFieldValue('primaryPsychologist')
  const startDate = useFieldValue('startDate')
  const endDate = useFieldValue('endDate')
  const closingReason = useFieldValue('closingReason')
  const referrerAgb = useFieldValue('referrerAgb')
  const productStart = useFieldValue('productStart')
  const productEnd = useFieldValue('productEnd')

  const isTreatmentType2022 = treatmentType.id === 5
  // Map property names to Dutch field names
  const missingFields = Object.values({
    // Required for all treatment types
    postalCode: patientPostalCode ? '' : t('postcode'),
    residence: patientResidence ? '' : t('residence'),
    treatmentType: treatmentType ? '' : t('treatmentType'),
    practice: practice ? '' : t('practice'),
    bankAccountNumber: primaryPsychologist?.practice?.bankAccountNumber
      ? ''
      : t('bankAccountNumber'),
    bankAccountName: primaryPsychologist?.practice?.bankAccountName
      ? ''
      : t('bankAccountName'),

    // Required for insured treatments
    startDate: !isInsured || (isInsured && startDate) ? '' : t('startDate'),
    endDate:
      isTreatmentType2022 || !isInsured || (isInsured && endDate) // not needed for 2022 & later
        ? ''
        : t('endDate'),
    closingReason:
      isTreatmentType2022 || !isInsured || (isInsured && closingReason) // not needed for 2022 & later
        ? ''
        : t('closingReason'),
    referrerAgb:
      !isInsured || (isInsured && referrerAgb) ? '' : t('agbReferrer'),
    productStart: !isInsured || zpm || productStart ? '' : t('productStart'),
    productEnd:
      isTreatmentType2022 || !isInsured || zpm || productEnd // not needed for 2022 & later
        ? ''
        : t('productEnd')
  })
    .filter((field) => !!field) // filter fields that are not missing
    .join(', ')

  return missingFields
}

// this component uses a lot of hooks and state
// making it a separate component avoids rerendering whole section
// whenever any of the fields used change
const AlertWrapper = ({ zpm }: { zpm: boolean }): JSX.Element => {
  const { t } = useTranslation('invoiceSection')
  const missingFields = useMissingFields(zpm)
  if (!missingFields) return <></>
  return (
    <Alert severity="warning">
      {t('warningText')} {missingFields}.
    </Alert>
  )
}

// this component uses a lot of hooks and state
// making it a separate component avoids rerendering whole section
// whenever any of the fields used change
const InvoiceDialogWrapper = ({
  invoiceType,
  selectedActivities,
  setSelectedActivities,
  zpm
}): JSX.Element => {
  const missingFields = useMissingFields(zpm)
  return (
    <InvoiceDialog
      partial={invoiceType === invoiceTypes.PARTIAL}
      disabled={!invoiceType || !!missingFields}
      selectedActivities={selectedActivities}
      setSelectedActivities={setSelectedActivities}
    />
  )
}

const InvoiceSection = ({ treatmentType, zpm }) => {
  const invoiceType =
    treatmentType === 1 ? invoiceTypes.FULL : invoiceTypes.PARTIAL
  const [selectedActivities, setSelectedActivities] = useState<any>([])

  const {
    input: { value: isLegacy }
  } = useField('isLegacy')

  const {
    consultationTypeOptions,
    psychologistOptions,
    durationOptions,
    consultationPrices,
    loading: loadingOptions
  } = useOptions(isLegacy)

  const { t } = useTranslation('invoiceSection')

  const invoices = useFieldValue('invoices')

  const handleActivitySelect = (isChecked, activity) => {
    if (isChecked) {
      setSelectedActivities((prevState) => [...prevState, activity])
    } else {
      setSelectedActivities((prevState) =>
        prevState.filter((el) => el.id !== activity.id)
      )
    }
  }

  if (loadingOptions) return <CircularProgress />

  return (
    <div>
      <AlertWrapper zpm={zpm} />

      <Table size="small" style={{ marginBottom: 20 }}>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{t('dateLabel')}</TableCell>
            <TableCell>{t('consultType')}</TableCell>
            <TableCell>{t('practitioner')}</TableCell>
            <TableCell>{t('duration')}&nbsp;(m)</TableCell>
            <TableCell>{t('amount')}</TableCell>
            <TableCell>{t('previouslyInvoiced')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <FieldArray name="activities2022">
            {({ fields }) => {
              return (
                <>
                  {fields.map((name) => {
                    const isSelected = !!selectedActivities.filter(
                      (el) => el.name === name
                    ).length
                    return (
                      <InvoiceActivity2022Row
                        key={name}
                        name={name}
                        consultationTypes={consultationTypeOptions}
                        psychologistOptions={psychologistOptions}
                        durationOptions={durationOptions}
                        prices={consultationPrices}
                        handleActivitySelect={handleActivitySelect}
                        isSelected={isSelected}
                      />
                    )
                  })}
                </>
              )
            }}
          </FieldArray>
        </TableBody>
      </Table>

      <InvoiceDialogWrapper
        invoiceType={invoiceType}
        zpm={zpm}
        selectedActivities={selectedActivities}
        setSelectedActivities={setSelectedActivities}
      />

      {invoices && <InvoiceList invoices={invoices} />}
    </div>
  )
}

export default InvoiceSection
