import { useState } from 'react'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import AtIcon from 'mdi-material-ui/At'
import LockQuestionIcon from 'mdi-material-ui/LockQuestion'
import CellphoneLockIcon from 'mdi-material-ui/CellphoneLock'
import authenticationService from '../../services/AuthenticationService'
import { PasswordField } from '../../components/PasswordField'
import { makeStyles } from '@material-ui/core/styles'
import { validateEmail } from '../../utils/validate'
import Tooltip from '@material-ui/core/Tooltip'
import { useNotificationBar } from '../../components/NotificationBar'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(2)
  },
  container: {
    padding: theme.spacing(3)
  }
}))

const LoginForm = () => {
  const { t } = useTranslation('loginPage')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [pin, setPin] = useState('')
  const classes = useStyles()
  const { showMessage, NotificationBar } = useNotificationBar({
    vertical: 'bottom',
    horizontal: 'center'
  })

  const handleSubmit = async () => {
    try {
      await authenticationService.login(email, password, pin)
    } catch (e) {
      showMessage(t('submitMessage'))
    }
  }

  const handleKeyDown = (ev) => {
    if (ev.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleSendPin = async (e) => {
    e.preventDefault()
    // NOTE: should always return success!
    await authenticationService.sendPin(email, password)
    showMessage(t('sendPinMessage'))
  }

  return (
    <Grid container>
      {NotificationBar}
      <Grid item md={3} sm={2} xs={1} />
      <Grid item md={6} sm={8} xs={10}>
        <Paper className={classes.paper}>
          <div className={classes.container}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <AtIcon />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="email"
                  label={t('emailLabel')}
                  type="email"
                  onChange={(event) => setEmail(event.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  autoFocus
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <LockQuestionIcon />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <PasswordField
                  id="password"
                  label={t('passwordLabel')}
                  type="password"
                  onChange={(event) => setPassword(event.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <CellphoneLockIcon />
              </Grid>
              <Grid item md={true} sm={true} xs={true}>
                <TextField
                  id="pin"
                  label={t('pinLabel')}
                  type="text"
                  onChange={(event) => setPin(event.target.value)}
                  onKeyDown={handleKeyDown}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
            <Grid container justify="center" style={{ marginTop: '10px' }}>
              <Grid item xs={3}>
                <Button
                  onClick={handleSubmit}
                  variant="outlined"
                  color="primary"
                  style={{ textTransform: 'none' }}
                >
                  {t('loginButtonText')}
                </Button>
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={3}>
                <Tooltip title={t('tooltipMessage')}>
                  <span>
                    <Button
                      type="button"
                      variant="outlined"
                      color="secondary"
                      disabled={!(validateEmail(email) && password !== '')}
                      style={{ textTransform: 'none' }}
                      onClick={handleSendPin}
                    >
                      {t('buttonPinText')}
                    </Button>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
      <Grid item md={3} sm={2} xs={1} />
    </Grid>
  )
}

export default LoginForm
