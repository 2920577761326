import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import React from 'react'
import { Funnel } from '../../../../types'
import DragableFunnelTableRow from './DragableFunnelTableRow'
import { client } from '../../../../client'

interface IFunnelTableProps {
  funnels: Array<Funnel>
  onStatusChange: any
  forceUpdate: () => void
}

const DragableFunnelTable: React.FunctionComponent<IFunnelTableProps> = ({
  funnels,
  onStatusChange,
  ...props
}) => {
  async function moveRowOneUp(funnelId: number) {
    await client.changeOrder(funnelId, 'up')
    props.forceUpdate()
  }

  async function moveRowUp(funnelId: number) {
    await client.changeOrder(funnelId, 'top')
    props.forceUpdate()
  }

  async function moveRowOneDown(funnelId: number) {
    await client.changeOrder(funnelId, 'down')
    props.forceUpdate()
  }

  async function moveRowDown(funnelId: number) {
    await client.changeOrder(funnelId, 'bottom')
    props.forceUpdate()
  }

  return (
    <Table size="small">
      <TableBody>
        {funnels.map((funnel) => (
          <DragableFunnelTableRow
            key={funnel.id}
            funnel={funnel}
            onStatusChange={onStatusChange}
            moveOneUp={() => moveRowOneUp(funnel.id)}
            moveOneDown={() => moveRowOneDown(funnel.id)}
            moveTop={() => moveRowUp(funnel.id)}
            moveBottom={() => moveRowDown(funnel.id)}
          />
        ))}
      </TableBody>
    </Table>
  )
}

export default DragableFunnelTable
