import React, { createContext, useReducer } from 'react'
import { LoadingIndicator } from '../../components/LoadingIndicator'
import BackOfficePageTable from './BackOfficePageTable'

export const StatusContext = createContext([])

export const BackOfficePageContent = ({ statuses }) => {
  // update for handleStatusChange in BackOfficePageTable (need to rerender table with new funnel moved from another table)
  const [update, forceUpdate] = useReducer((x) => x + 1, 0)
  if (!statuses) {
    return <LoadingIndicator />
  }

  return (
    <StatusContext.Provider
      value={statuses.map((status) => ({
        label: status.name,
        value: status.id,
        stage: status.stage,
        isVisible: status.isVisible
      }))}
    >
      {statuses
        .filter((status) => status.isVisible) // only show visible statuses
        .map((status) => {
          return (
            <BackOfficePageTable
              key={status.id}
              status={status}
              update={update}
              forceUpdate={forceUpdate}
            />
          )
        })}
    </StatusContext.Provider>
  )
}

export default BackOfficePageContent
