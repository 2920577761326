import {
  useRef,
  useCallback,
  useEffect,
  RefObject,
  FormEventHandler
} from 'react'
import { client } from '../../../client'
import { Practice } from '../../../types'

type FormRef = RefObject<HTMLFormElement>
type FormOnChange = FormEventHandler<HTMLFormElement>

export const useAutosave = (): [FormRef, FormOnChange] => {
  const formRef = useRef<HTMLFormElement>(null)
  const timer = useRef<number>(0)
  const changes = useRef<Record<string, Partial<Practice>>>({})

  const saveChanges = useCallback(() => {
    const isValid = formRef.current?.reportValidity()
    if (!isValid) {
      return
    }
    console.log('saving changes')
    const changesToApply = Object.entries(changes.current)
    changes.current = {}
    for (const [id, change] of changesToApply) {
      client.updatePractice(parseInt(id), change)
    }
  }, [])

  const onChange = useCallback(
    (e) => {
      const { name, value } = e.target
      const [id, field] = name.split('.')
      if (!changes.current[id]) changes.current[id] = {}
      changes.current[id][field] = value
      window.clearTimeout(timer.current)
      timer.current = window.setTimeout(saveChanges, 1000)
    },
    [saveChanges]
  )

  // save changes when the component unmounts
  useEffect(() => saveChanges, [saveChanges])

  return [formRef, onChange]
}
