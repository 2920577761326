import {useTranslation} from "react-i18next";
import {useNotificationBar} from "../../../../components/NotificationBar";
import {useState} from "react";
import {useFieldValue} from "../../../../hooks/useFieldValue";
import {Form, useForm} from "react-final-form";
import useEffectAsync from "../../../../hooks/useEffectAsync";
import {client} from "../../../../client";
import {LoadingIndicator} from "../../../../components/LoadingIndicator";
import arrayMutators from "final-form-arrays";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {TextField} from "mui-rff";
import DialogActions from "@material-ui/core/DialogActions";
import InvoiceRows from "./InvoiceRows";


const InvoiceDialog = ({
                           disabled,
                           partial,
                           selectedActivities,
                           setSelectedActivities
                       }) => {
    const { t } = useTranslation('invoiceSection');
    // @ts-ignore
    const { showMessage, NotificationBar } = useNotificationBar();
    const [open, setOpen] = useState(false);
    const treatmentProgramId = useFieldValue('id');
    const treatmentTypeId = useFieldValue('treatmentType.id');
    const form = useForm();
    const [program, setProgram] = useState<any>();
    const [loading, setLoading] = useState(false);

    useEffectAsync(async () => {
        if (open) {
            setProgram(await client.getTreatmentProgramById(treatmentProgramId));
        }
    }, [open]);

    const handleOpen = () => {
        if (!selectedActivities.length && treatmentTypeId === 5) {
            showMessage(t('selectInvoiceMessage'), 'info');
            return;
        }

        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const handleSubmit = async (values, treatmentProgramId) => {
        setLoading(true);

        // react-final-form initializes the invoiceRows property with an empty object,
        // which we remove.
        values.invoiceRows = values.invoiceRows.filter(
            (row) => !!row && Object.keys(row).length > 0
        );
        values.treatmentProgram = { id: treatmentProgramId };

        const createdInvoice = await client.createInvoice(values);

        if (createdInvoice.status === 'success') {
            showMessage(t('createInvoiceSuccess'));
            const invoices = await client.getInvoicesByTreatmentProgram(
                treatmentProgramId
            );
            form.mutators.setValue('invoices', invoices);

            selectedActivities.forEach(({ name }) =>
                form.mutators.setValue(`${name}.invoiceRow`, {})
            );
        } else {
            if (createdInvoice?.data?.id) {
                await client.deleteInvoice(createdInvoice.data.id); // remove faulty invoice
            }
            showMessage(t('createInvoiceError'), 'error');
        }

        setSelectedActivities([]);
        setLoading(false);
        handleClose();
    };

    let invoiceRows;

    if (program?.treatmentType?.id === 1) {
        // insured < 2022
        invoiceRows = [];
    } else if (program?.treatmentType?.id === 5 || program?.treatmentType?.id === 2) {
        // insured >= 2022
        invoiceRows = selectedActivities?.length
            ? selectedActivities.map((activity) => ({
                registeredActivity2022: activity.id,
                date: activity.date,
                amount: activity.amount,
                isEditDisabled: true,
                vatPercentage: '0',
                description: activity.description
            }))
            : [];
        //Probably not needed - why were there some mocked data?
    // } else if (program?.treatmentType?.id === 2) {
    //     // Self-paying (Onverzekerde zorg)
    //     invoiceRows = [
    //         {
    //             date: new Date(),
    //             vatPercentage: '0',
    //             amount: '117.33',
    //             description: 'Consult (45 min.)'
    //         }
    //     ];
    } else {
        // else Self-paying
        invoiceRows = [{}];
    }

    if (loading) {
        return <LoadingIndicator />;
    }

    return (
        <Form
            initialValues={{ invoiceNumber: '', invoiceRows }}
            onSubmit={(values) => handleSubmit(values, treatmentProgramId)}
            subscription={{ invalid: true, pristine: true }}
            mutators={{
                ...arrayMutators
            }}
            render={({ handleSubmit, invalid, pristine }) => {
                return (
                    <div>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleOpen}
                            disabled={disabled}
                        >
                            {t('createInvoice')}
                        </Button>
                        <Dialog
                            fullWidth
                            maxWidth={false}
                            open={open}
                            onClose={handleClose}
                        >
                            <DialogTitle>{t('createInvoice')}</DialogTitle>
                            <DialogContent>
                                {!program ? (
                                    <LoadingIndicator />
                                ) : (
                                    <form
                                        id="createInvoiceForm"
                                        onSubmit={handleSubmit}
                                        autoComplete="off"
                                    >
                                        <TextField
                                            name="invoiceNumber"
                                            label={t('factureNumber')}
                                            fullWidth
                                            autoFocus
                                            required
                                            fieldProps={{
                                                validate: (value) =>
                                                    !value ? t('factureNumberRequired') : undefined
                                            }}
                                        />
                                        <TextField
                                            name="reference"
                                            label={t('referenceLabel')}
                                            fullWidth
                                        />
                                        <TextField
                                            name="remarks"
                                            label={t('commentsLabel')}
                                            fullWidth
                                            multiline
                                        />
                                        {partial && (
                                            // @ts-ignore
                                            <InvoiceRows
                                                treatmentTypeId={program?.treatmentType?.id}
                                            />
                                        )}
                                    </form>
                                )}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    {t('cancel')}
                                </Button>
                                <Button
                                    disabled={invalid}
                                    onClick={handleSubmit}
                                    color="primary"
                                    autoFocus
                                >
                                    {t('invoiceCreateButtonText')}
                                </Button>
                            </DialogActions>
                        </Dialog>
                        {NotificationBar}
                    </div>
                );
            }}
        />
    );
};

export default InvoiceDialog;

