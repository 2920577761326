import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import logo from '../images/logo.png'
import AsyncSelect from 'react-select/async'
import IconButton from '@material-ui/core/IconButton'
import { client } from '../client'
import { Link, withRouter } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AuthenticationService from '../services/AuthenticationService'
import SettingsIcon from '@material-ui/icons/Settings'
import ShieldKeyOutlineIcon from 'mdi-material-ui/ShieldKeyOutline'
import LogoutIcon from 'mdi-material-ui/Logout'
import Tooltip from '@material-ui/core/Tooltip'
import CalendarClockIcon from 'mdi-material-ui/CalendarClock'
import PeopleAltOutlinedIcon from '@material-ui/icons/PeopleAltOutlined'
import { AlertDialog } from '../components/AlertDialog'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import ScienceIcon from '@material-ui/icons/Build'
import debounce from 'lodash.debounce'
import { useCallback } from 'react'
import BarChartIcon from '@material-ui/icons/BarChart'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: 1399
  },
  link: {
    textDecoration: 'none'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  grow: {
    flexGrow: 1
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  profileMenu: {
    zIndex: 1400,
    marginTop: 50
  },
  logo: {
    width: 150,
    opacity: 0.4
  }
}))

const TopBar = ({ history }) => {
  const { t } = useTranslation('topBarComponent')
  const classes = useStyles()

  /*
    ASYNCHRONOUS
   */
  const promiseOptions = async (inputValue) => {
    if (!inputValue) {
      return
    }

    const patients = await client.searchPatients(inputValue)
    const uniquePatients = Object.values(
      patients.reduce(
        (acc, patient) => ({
          ...acc,
          [patient.id]: patient
        }),
        {}
      )
    )
    return uniquePatients.map((patient) => ({
      value: patient.id,
      label: `${[patient.firstName, patient.infix, patient.lastName]
        .filter(Boolean)
        .join(' ')}`
    }))
  }

  const loadOptions = useCallback(
    debounce((inputValue, callback) => {
      promiseOptions(inputValue).then((options) => callback(options))
    }, 500),
    []
  )

  /*
    HANDLERS
   */
  const handleLogout = () => {
    AuthenticationService.logout()
  }

  const handleSelectPatient = async (option) => {
    const { value: id } = option
    const programIds = await client.getSortedTreatmentPrograms(id)
    let url
    if (programIds.length) {
      // Load treatment program if there is any
      const programId = programIds[programIds.length - 1]
      url = `/clienten/${id}/trajecten/${programId}`
    } else {
      // Otherwise we show just the client card
      const funnels = await client.getFunnelsByPatientId(id)
      const funnelId = funnels[0]?.id
      url = `/wachtlijst/${funnelId}`
    }
    history.push(url)
  }

  return (
    <>
      <AppBar
        style={{ color: 'grey', backgroundColor: '#ffffff' }}
        position="fixed"
        className={classes.appBar}
      >
        <Toolbar>
          <Link to="/">
            <img
              className={classes.logo}
              src={logo}
              alt="Het Psychologencollectief"
            />
          </Link>
          <div style={{ marginLeft: 100, width: 500 }}>
            <AsyncSelect
              style={{ width: '100%' }}
              defaultOptions
              loadOptions={loadOptions}
              onChange={handleSelectPatient}
              placeholder={t('selectPlaceholder')}
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <Tooltip title={t('tooltipClientTitle')}>
              <IconButton color="inherit" component={Link} to="/clienten">
                <PeopleAltOutlinedIcon />
              </IconButton>
            </Tooltip>
            {AuthenticationService.isAdministrator() && (
              <>
                <Tooltip title={t('tooltipWaitinglistTitle')}>
                  <IconButton color="inherit" component={Link} to="/wachtlijst">
                    <CalendarClockIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t('tooltipStatisticsTitle')}>
                  <IconButton
                    color="inherit"
                    component={Link}
                    to="/statistieken"
                  >
                    <BarChartIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {(AuthenticationService.isSuperUser() ||
              AuthenticationService.isBackOffice()) && (
              <>
                <Tooltip title="Whitelist">
                  <IconButton color="inherit" component={Link} to="/whitelist">
                    <ShieldKeyOutlineIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            {AuthenticationService.isSuperUser() && (
              <>
                <Tooltip title={t('tooltipAdduserTitle')}>
                  <IconButton
                    color="inherit"
                    component={Link}
                    to="/gebruikers/toevoegen"
                  >
                    <PersonAddIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
            <Tooltip title={t('tooltipSettingsTitle')}>
              <IconButton color="inherit" component={Link} to="/instellingen">
                <SettingsIcon />
              </IconButton>
            </Tooltip>
            {AuthenticationService.isSuperUser() && (
              <Tooltip title={'Experimental Admin Tools'}>
                <IconButton color="inherit" component={Link} to="/admin-tools">
                  <ScienceIcon />
                </IconButton>
              </Tooltip>
            )}
            <AlertDialog
              text={t('logOutConfirm')}
              title={t('alertTitle')}
              cancelText={t('cancel')}
              confirmText={t('alertTitle')}
              onConfirm={handleLogout}
              render={({ onClick }) => (
                <Tooltip title={t('alertTitle')}>
                  <IconButton color="inherit" onClick={onClick}>
                    <LogoutIcon />
                  </IconButton>
                </Tooltip>
              )}
            />
          </div>
        </Toolbar>
      </AppBar>
    </>
  )
}

export default withRouter(TopBar)
