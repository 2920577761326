import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import FunnelTable from "./FunnelTable";
import React from "react";
import {Funnel} from "../../../types";

interface IStatusBlockProps {
    funnels: Array<Funnel>;
    name: any;
    anchor: any;
    onStatusChange: any;
}

const StatusBlock: React.FunctionComponent<IStatusBlockProps> = ({ name, anchor, funnels, onStatusChange }) => (
    <div>
        <a href={`#${anchor}`} id={anchor}>
            {/* why empty div here? */}
            <div />
        </a>
        <Card style={{ marginBottom: 20 }}>
            <CardContent>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    {name}
                </Typography>
                <FunnelTable funnels={funnels} onStatusChange={onStatusChange} />
            </CardContent>
        </Card>
    </div>
);

export default StatusBlock;