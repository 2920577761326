import { useEffect, useRef } from 'react'
import { FormSpy, useForm } from 'react-final-form'
import { useStoreActions } from 'easy-peasy'
import { client } from '../client'
import { useFieldValue } from '../hooks/useFieldValue'
import AuthenticationService from '../services/AuthenticationService'
import useEffectAsync from '../hooks/useEffectAsync'

const FormActions = ({ values }) => {
  const setSelfPayer = useStoreActions((actions) => actions.user.setSelfPayer)
  const setSessionNotesCounter = useStoreActions(
    (actions) => actions.user.setSessionNotesCounter
  )
  const openDialog = useStoreActions((actions) => actions.user.openDialog)
  const userId = AuthenticationService.getUserId()
  const practiceId = useFieldValue('practice.id')
  const intakeNote = useFieldValue('intakeNote')
  const acquaintanceNote = useFieldValue('acquaintanceNote')
  const form = useForm()
  const isFirstRun = useRef(true)

  const getText = async (fieldName) => {
    const response = await client.getTemplate(userId, practiceId, fieldName)

    if (response.status !== 'success') {
      return null
    }

    if (!response.data.template) {
      console.log(
        `Er is iets misgegaan bij het uitpakken van het sjabloon voor ${fieldName}.`
      )
      return null
    }

    return response.data.template.text
  }

  const setTexts = async () => {
    const newIntakeNote = await getText('intakeNote')
    const newAcquaintanceNote = await getText('acquaintanceNote')
    const newTreatmentPlan = await getText('treatmentPlan')
    form.mutators.setValue('intakeNote', newIntakeNote || '​')
    form.mutators.setValue('acquaintanceNote', newAcquaintanceNote || '​')
    form.mutators.setValue('treatmentPlan', newTreatmentPlan || '​')
  }

  const isEmpty = (value) => {
    return !value || value.length <= 2
  }

  const handlePracticeChange = () => {
    if (!isEmpty(intakeNote) || !isEmpty(acquaintanceNote)) {
      openDialog({
        title: 'Wil je de gegevens overschrijven?',
        text: 'Je kunt het sjabloon inladen, maar dan overschrijf je de huidige gegevens. Wil je overschrijven?',
        confirmText: 'Overschrijven',
        cancelText: 'Behouden',
        onConfirm: setTexts
      })
    } else {
      setTexts()
    }
  }

  useEffectAsync(async () => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    handlePracticeChange()
  }, [values.practice])

  useEffect(() => {
    if (values.treatmentType) {
      setSelfPayer(values.treatmentType.id === 2)
    }
    if (values.sessionNotes) {
      setSessionNotesCounter(values.sessionNotes.length)
    }
  }, [values.treatmentType, values.sessionNotes])

  return null
}

const FormActionSpy = (props) => (
  <FormSpy {...props} subscription={{ values: true }} component={FormActions} />
)

export default FormActionSpy
