import FormSection from '../RecordPage/FormSection'
import EditTemplatesSection from './sections/EditTemplatesSection'
import ChangePasswordSection from './sections/ChangePasswordSection'
import { useTranslation } from 'react-i18next'

const SettingsPageContent = () => {
  const { t } = useTranslation('settingsPage')

  return (
    <div>
      <FormSection
        anchor="changePassword"
        title={t('changePassword')}
        component={ChangePasswordSection}
      />
      <FormSection
        anchor="customTemplates"
        title={t('templates')}
        component={EditTemplatesSection}
      />
    </div>
  )
}

export default SettingsPageContent
