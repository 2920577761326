import { useState } from 'react'
import { PlusCircle } from 'mdi-material-ui'
import Button from '@material-ui/core/Button'
import DisorderDialog from './DisorderDialog'
import { FieldArray } from 'react-final-form-arrays'
import Typography from '@material-ui/core/Typography'
import DeleteIcon from '@material-ui/icons/Delete'
import IconButton from '@material-ui/core/IconButton'
import { makeStyles } from '@material-ui/core/styles'
import { client } from '../../../../client'
import Field from '../../../../react-final-form-wrappers/Field'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  selectedOption: {
    marginTop: 10,
    marginLeft: 15,
    marginRight: 10
  }
}))

const DisorderSection = () => {
  const { t } = useTranslation('disorderSection')
  const [dialogOpen, setDialogOpen] = useState(false)
  const {
    input: { value: programId }
  } = useField('id')

  const handleDialogOpen = () => {
    setDialogOpen(true)
  }

  const handleRemoveDisorder = async ({ id }) => {
    await client.removeDisorder(id)
  }

  const classes = useStyles()

  return (
    <div>
      <Typography display="block" variant="caption" color="textSecondary">
        {t('diagnose')}
      </Typography>
      <FieldArray
        name="disorders"
        render={({ fields }) => (
          <div>
            <div>
              {fields.map((name, index) => (
                <Field
                  name={`${name}`}
                  key={name}
                  render={({ input: { value } }) => (
                    <div style={{ overflow: 'hidden' }}>
                      <Typography
                        className={classes.selectedOption}
                        style={{ float: 'left' }}
                        component="p"
                        variant="body1"
                      >
                        {value.disorder.name}
                      </Typography>
                      <IconButton
                        style={{ float: 'left' }}
                        onClick={() =>
                          handleRemoveDisorder(fields.remove(index))
                        }
                      >
                        <DeleteIcon color="disabled" fontSize="small" />
                      </IconButton>
                    </div>
                  )}
                />
              ))}
            </div>
            <div>
              <Button onClick={handleDialogOpen} color="primary" size="small">
                <PlusCircle />
                {t('diagnoseSelection')}
              </Button>
              <DisorderDialog
                onSubmit={(disorders) => {
                  disorders.forEach(async (disorder) => {
                    const newDisorder = await client.addDisorder(
                      disorder.id,
                      programId,
                      false
                    )
                    fields.push(newDisorder)
                  })
                }}
                open={dialogOpen}
                setOpen={setDialogOpen}
              />
            </div>
          </div>
        )}
      />
    </div>
  )
}

export default DisorderSection
