import { useState } from 'react'
import { client } from '../../client'
import MaterialTable from 'material-table'
import Folder from '@material-ui/icons/Folder'
import useEffectAsync from '../../hooks/useEffectAsync'
import { PageLoadingIndicator } from '../../components/PageLoadingIndicator'
import icons from '../../utils/icons'
import { useHistory } from 'react-router-dom'
import nlLocale from 'date-fns/locale/nl'
import { format } from 'date-fns'
import Typography from '@material-ui/core/Typography'
import { useTranslation } from 'react-i18next'

const PatientSelectPageContent = () => {
  const { t } = useTranslation('patientSelectPage')
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const history = useHistory()

  useEffectAsync(async () => {
    setLoading(true)
    setCount(await client.getPatientCount())
    setLoading(false)
  }, [])

  const getFullName = (patient) => {
    const { firstName, infix, lastName } = patient
    return [firstName, infix, lastName].join(' ')
  }

  const getData = async (query) => {
    const patients = await client.getPatients(query.pageSize, query.page)

    if (!patients || !patients.length) {
      return {
        data: [],
        page: query.page,
        totalCount: 0
      }
    }

    patients.forEach((patient) => {
      patient.fullName = getFullName(patient)

      const { birthDate, lastModifiedAt } = patient
      if (birthDate) {
        patient.shortBirthDate = format(new Date(birthDate), 'P', {
          locale: nlLocale
        })
      }

      patient.lastModifiedAt = format(new Date(lastModifiedAt), 'Pp', {
        locale: nlLocale
      })
    })
    return {
      data: patients,
      page: query.page,
      totalCount: count.numPatients
    }
  }

  const openRecord = async (event, rowData) => {
    const programIds = await client.getSortedTreatmentPrograms(rowData.id)
    const programId = programIds[programIds.length - 1]
    history.push(`/clienten/${rowData.id}/trajecten/${programId}`)
  }

  if (loading) {
    return <PageLoadingIndicator />
  } else {
    return (
      <MaterialTable
        title={
          <Typography variant="subtitle1">
            {count.caseload || 0} {t('subtitleOne')}
          </Typography>
        }
        columns={[
          {
            title: t('fullNameColumn'),
            field: 'fullName'
          },
          {
            title: t('shortBirthDateColumn'),
            field: 'shortBirthDate'
          },
          {
            title: t('lastModifiedColumn'),
            field: 'lastModifiedAt'
          }
        ]}
        data={getData}
        icons={icons}
        options={{ search: false }}
        actions={[
          {
            icon: () => <Folder color="disabled" />,
            tooltip: t('tooltipText'),
            onClick: openRecord
          }
        ]}
        onRowClick={openRecord}
        localization={{
          header: {
            actions: ''
          },
          body: { emptyDataSourceMessage: t('emptyDataMessage') },
          pagination: {
            labelDisplayedRows: `{from}-{to} ${t(
              'labelDisplayedRows'
            )} {count}`,
            labelRowsSelect: t('labelRowsSelected'),
            labelRowsPerPage: t('labelRowsPerPage'),
            firstAriaLabel: t('firstAria'),
            firstTooltip: t('firstTooltip'),
            previousAriaLabel: t('previousAria'),
            previousTooltip: t('previousTooltip'),
            nextAriaLabel: t('nextAria'),
            nextTooltip: t('nextTooltip'),
            lastAriaLabel: t('lastAria'),
            lastTooltip: t('lastTooltip')
          }
        }}
      />
    )
  }
}

export default PatientSelectPageContent
