import { useState } from 'react'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { withRouter } from 'react-router-dom'
import { client } from '../../../../client'
import useEffectAsync from '../../../../hooks/useEffectAsync'
import { LoadingIndicator } from '../../../../components/LoadingIndicator'
import { useTranslation } from 'react-i18next'

const TreatmentProgramSelector = ({ match }) => {
  const { t } = useTranslation('treatmentProgramSelector')

  const { patientId, treatmentProgramId } = match.params
  const [programIds, setProgramIds] = useState([])

  useEffectAsync(async () => {
    setProgramIds(await client.getSortedTreatmentPrograms(patientId))
  }, [])

  const handleSelectProgram = (event) => {
    const programId = event.target.value
    window.location.href = `/clienten/${patientId}/trajecten/${programId}`
  }

  if (!programIds.length) {
    return <LoadingIndicator />
  }

  return (
    <FormControl>
      <InputLabel htmlFor="treamentProgramSelector">
        {t('treatmentProgram')}
      </InputLabel>
      <Select
        style={{ width: 200 }}
        value={treatmentProgramId}
        onChange={handleSelectProgram}
        inputProps={{
          name: 'treamentProgramSelector',
          id: 'treamentProgramSelector'
        }}
      >
        {programIds.map((id, idx) => (
          <MenuItem key={id} value={id}>
            {t('treatmentProgram')} {idx + 1}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default withRouter(TreatmentProgramSelector)
