import parse from 'date-fns/parse'
import format from 'date-fns/format'
import nlLocale from 'date-fns/locale/nl'

/**
 * @param {string | null | undefined} dateTime
 * @returns {string | undefined}
 */
export const dateTimeToDate = (dateTime) => {
  if (!dateTime) {
    return undefined
  }
  if (typeof dateTime === 'object') {
    dateTime = dateTime.toISOString()
  }
  const formatString = 'yyyy-MM-ddHH:mm:ss.SSSX'
  const parsed = parse(dateTime.replace('T', ''), formatString, new Date())
  return format(parsed, 'dd-MM-yyyy', {
    locale: nlLocale
  })
}

export const dateTimeToVerboseString = (dateTime, formatString = 'PPPp') => {
  if (typeof dateTime === 'string') {
    dateTime = new Date(dateTime)
  }
  return format(dateTime, formatString, {
    locale: nlLocale
  })
}
