const WATCH_LIST_STATUSES = [
    "Wachtlijst: 1e keer teruggebeld en optie gemaild",
    "Wachtlijst: 2e keer teruggebeld",
    "Wachtlijst: 3e keer teruggebeld en uitnodiging gemaild",
    "Bel-me-terugformulier ingevuld",
    "Telefonisch contact met ons opgenomen",
    "Per e-mail contact met ons opgenomen",
    "Doorverwezen via Zorgdomein",
    "Doorverwezen op andere wijze",
    "Algemene wachtlijst",
    "1e keer teruggebeld",
    "2e keer teruggebeld",
    "3e keer teruggebeld",
    "3e keer teruggebeld en uitnodiging gemaild",
    "Voorstel voor kennismaking gemaild"

];

const isOnWatchList = (status: {name?: string}): boolean =>  !!status?.name && WATCH_LIST_STATUSES.includes(status?.name);

export default isOnWatchList;