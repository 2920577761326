import Drawer from '@material-ui/core/Drawer'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { HashLink as Link } from 'react-router-hash-link'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { useTranslation } from 'react-i18next'

export const drawerWidth = 260
export const scale = 0.95
export const padding = 5
export const scrollOffset = 75

export const renderMenuItem = (text, anchorName, IconComponent) => {
  return (
    <Link
      style={{ textDecoration: 'none', color: 'black' }}
      smooth
      to={`#${anchorName}`}
      scroll={(el) => scrollWithOffset(el, scrollOffset)}
    >
      <ListItem
        style={{ transform: `scale(${scale})`, padding: padding }}
        button
        key={text}
      >
        <ListItemIcon>{IconComponent ? <IconComponent /> : <></>}</ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ variant: 'body2' }}
          primary={text}
        />
      </ListItem>
    </Link>
  )
}

export const scrollWithOffset = (el, offset) => {
  const elementPosition = el.offsetTop - offset
  window.scroll({
    top: elementPosition,
    left: 0,
    behavior: 'smooth'
  })
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth
  },
  drawerPaper: {
    width: drawerWidth
  },
  toolbar: theme.mixins.toolbar,
  link: {
    textDecoration: 'none',
    color: 'black'
  },
  noShow: {
    transition: '1000ms all ease',
    opacity: 0,
    willChange: 'opacity'
  },
  show: {
    opacity: 0.5
  }
}))

const UpdateIndicator = ({ show }) => {
  const { t } = useTranslation('menuComponent')
  const classes = useStyles()

  return (
    <Typography
      className={show ? classes.show : classes.noShow}
      variant="caption"
      display="block"
      align="center"
      gutterBottom
    >
      {t('savedChanges')}
    </Typography>
  )
}

const Menu = ({ showUpdated, children }) => {
  const classes = useStyles()

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper
      }}
      anchor="left"
    >
      <div className={classes.toolbar} />
      {children}

      <UpdateIndicator show={showUpdated} />
    </Drawer>
  )
}

export default Menu
