import { useTranslation } from 'react-i18next'
import Collapse from '@material-ui/core/Collapse'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import AuthenticationService from '../../../services/AuthenticationService'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { format } from 'date-fns'
import { nl } from 'date-fns/locale'
import getFullName from './getFullName'
import React from 'react'
import { AlertDialog } from '../../../components/AlertDialog'
import { client } from '../../../client'

interface IStatusLogProps {
  funnelId: string
  statusLog: any
  open: boolean
  practice: any
  location: any
}
export const StatusLog: React.FunctionComponent<IStatusLogProps> = ({
  funnelId,
  statusLog,
  open,
  practice,
  location
}) => {
  const { t } = useTranslation('backOfficePage')

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Box margin={1}>
        <Table
          size="small"
          style={{ minWidth: 700, maxWidth: 'max(80%,900px)' }}
        >
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>{t('date')}</TableCell>
              <TableCell>{t('user')}</TableCell>
              <TableCell>{t('status')}</TableCell>
              <TableCell>{t('practice')}</TableCell>
              <TableCell>{t('location')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statusLog.map((item, index, array) => (
              <TableRow key={item.id}>
                <TableCell>
                  {array.length > 1 &&
                    index === array.length - 1 &&
                    item.user.id === AuthenticationService.getUserId() && (
                      //@ts-ignore
                      <AlertDialog
                        text={`${t('alertLogText')} ${
                          array[index - 1].status.name
                        }`}
                        title={t('alertLogTitle')}
                        cancelText={t('alertLogCancel')}
                        confirmText={t('alertLogConfirm')}
                        onConfirm={() => {
                          client.removeLastFunnelStatusLogItem(funnelId)
                          window.location.reload()
                        }}
                        render={({ onClick }) => (
                          <IconButton style={{ padding: 0 }} onClick={onClick}>
                            <DeleteIcon
                              style={{ fontSize: '1.2rem' }}
                              color="disabled"
                            />
                          </IconButton>
                        )}
                      />
                    )}
                </TableCell>
                <TableCell component="th" scope="row">
                  {format(new Date(item.createdAt), 'Pp', {
                    locale: nl
                  })}
                </TableCell>
                <TableCell>{getFullName(item.user)}</TableCell>
                <TableCell>{item.status.name}</TableCell>
                <TableCell>{practice?.name}</TableCell>
                <TableCell>{location?.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Collapse>
  )
}
