import FormRow from '../../FormRow'
import {
  Field,
  RichTextEditor,
  SelectCheckbox
} from '../../../../react-final-form-wrappers'
import { OnChange } from 'react-final-form-listeners'
import { client } from '../../../../client/client'
import { useFieldValue } from '../../../../hooks/useFieldValue'
import { useTranslation } from 'react-i18next'

const AcquaintanceSection = () => {
  const { t } = useTranslation('acquaintanceSection')
  const programId = useFieldValue('id')

  const options = [
    { id: 15, name: t('scheduledIntake') },
    { id: 17, name: t('clientRefrains') },
    { id: 18, name: t('clientUnavailable') },
    { id: 999, name: t('clientCanceledIntroductionMeeting') }
  ]

  return (
    <>
      <FormRow>
        <Field
          name="acquaintanceNote"
          label={t('acquaintanceNote')}
          component={RichTextEditor}
        />
      </FormRow>
      <FormRow>
        <Field
          name="acquaintanceOutcome"
          label={t('acquaintanceOutcome')}
          component={SelectCheckbox}
          options={options}
        />
      </FormRow>
      <OnChange name="acquaintanceOutcome">
        {(option, previousOption) => {
          if (option && option.id) {
            client.updateFunnelStatusByTreatmentProgram(programId, option.id)
          }
        }}
      </OnChange>
    </>
  )
}

export default AcquaintanceSection
