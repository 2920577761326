import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import groupBy from 'lodash.groupby'
import useEffectAsync from '../../../hooks/useEffectAsync'
import CommonLineBarChart from '../charts/CommonLineBarChart'
import { useThemeColor } from '../StatisticsPageContent'
import { client } from '../../../client'
import ChartNoData from '../charts/ChartNoData'

const Age = () => {
  const { t } = useTranslation('statisticsPage')
  const [data, setData] = useState([])
  const themeColor = useThemeColor()

  useEffectAsync(async () => {
    setData(await client.age())
  }, [])

  const title = t('age')
  if (!data.length) return <ChartNoData title={title} />

  const mapData = data.map((item) => {
    return {
      name: item.id,
      age: Number(item.age)
    }
  })

  const groupedDataByAge = groupBy(mapData, 'age')
  const agesArray = Object.keys(groupedDataByAge)
  const histogramData = agesArray.map((age) => {
    return {
      name: age,
      count: groupedDataByAge[age].length
    }
  })

  const resData = {
    rawData: histogramData,
    data: histogramData,
    bars: [{ name: 'count', color: themeColor }],
    title
  }
  return <CommonLineBarChart data={resData} />
}

export default Age
