import TopBar from './TopBar'
import PageRoutes from '../pages/PageRoutes'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  }
}))

const Layout = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <TopBar />
      <PageRoutes />
    </div>
  )
}

export default Layout
