import { useEffect } from 'react'

// Promises and useEffect(async () => ...) in useEffect, but we can use an
// async function inside the `effect` function. This hook simply wraps a
// (possibly) async method so we can use it without thinking.
export default function useEffectAsync(effect, inputs) {
  useEffect(() => {
    effect()
  }, inputs)
}
