import { StatusLog } from '../../../BackOfficePage/BackOfficePageTable/StatusLog';

const ContactHistorySection = ({ funnel }) => {
  return (
    <StatusLog
      showRemoveButton={false}
      funnelId={funnel.id}
      statusLog={funnel.statusLog}
      open={true}
    />
  );
};

export default ContactHistorySection;
