import { action } from 'easy-peasy'

const defaultDialogState = {
  isOpen: false,
  title: '',
  text: '',
  cancelText: 'Annuleren',
  confirmText: 'OK',
  onConfirm: () => undefined
}

const patientModel = {
  selfPayer: true,
  sessionNotesCounter: 0,
  updatedJustNow: false,
  isZPM: false,

  dialog: defaultDialogState,

  setSessionNotesCounter: action((state, count) => {
    state.sessionNotesCounter = count
  }),
  setSelfPayer: action((state, isSelfPayer) => {
    state.selfPayer = isSelfPayer
  }),
  setUpdatedJustNow: action((state, value) => {
    state.updatedJustNow = value
  }),
  setIsZPM: action((state, value) => {
    state.isZPM = value
  }),
  openDialog: action((state, dialogState) => {
    state.dialog = { ...dialogState, isOpen: true }
  }),
  closeDialog: action((state) => {
    state.dialog = defaultDialogState
  })
}

export default patientModel
