import {
  MenuItem,
  Paper,
  TextField,
  Select,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useCallback, useState } from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'
import { client } from '../../../client'
import useEffectAsync from '../../../hooks/useEffectAsync'
import CustomizedAxisTick from '../charts/AxisTick'
import { useThemeColor } from '../StatisticsPageContent'
import { LoadingIndicator } from '../../../components/LoadingIndicator'
import { useAsyncData } from '../../../utils/useAsyncData'
import { Psychologist } from '../../../types'
import { useTranslation } from 'react-i18next'
import { DataGrid, GridColumns } from '@material-ui/data-grid'

type UsageStat = {
  price_code: string
  activity_count: number
}

type PsychologistSelectorControlledProps = {
  onChange: (value: Psychologist | null) => void
  value: Psychologist | null
}
const PsychologistSelectorControlled = ({
  onChange,
  value
}: PsychologistSelectorControlledProps): JSX.Element => {
  const [options, loading] = useAsyncData<Psychologist[]>(async () =>
    client.getPsychologistOptions(true)
  )
  const getFullName = useCallback((psychologist: Psychologist): string => {
    if (!psychologist.user) return ''
    const { firstName, infix, lastName } = psychologist.user
    return [firstName, infix, lastName].filter(Boolean).join(' ')
  }, [])

  const sortFn = useCallback(
    (a: Psychologist, b: Psychologist): number => {
      if (!a.user) return -1
      if (!b.user) return 1
      return getFullName(a).localeCompare(getFullName(b))
    },
    [getFullName]
  )

  if (loading || !options) return <LoadingIndicator />

  const sortedOptions = options.filter((o) => !!o.user).sort(sortFn)

  return (
    <Autocomplete
      renderInput={(params) => <TextField {...params} label={'Behandelaar'} />}
      getOptionLabel={getFullName}
      options={sortedOptions}
      value={value}
      onChange={(_, value) => onChange(value)}
    />
  )
}

const UsageChart = ({ stats }: { stats: UsageStat[] }) => {
  const themeColor = useThemeColor()
  const min = Math.min(...stats.map((el) => el.activity_count))
  const max = Math.max(...stats.map((el) => el.activity_count))
  const data = stats.map(({ price_code, activity_count }) => ({
    name: price_code,
    value: activity_count
  }))

  return (
    <div style={{ width: '100%', height: 400 }}>
      <ResponsiveContainer>
        <BarChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            height={70}
            tick={CustomizedAxisTick}
            interval={0}
          />
          <YAxis domain={[min, max]} />
          <Tooltip />
          <Bar dataKey="value" name="price code usage" fill={themeColor} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}

const UsageTable = ({ stats }: { stats: UsageStat[] }): JSX.Element => {
  const rows = stats.map((s, i) => ({ ...s, id: i }))
  const columns: GridColumns = [
    { field: 'price_code', headerName: 'Prijscode', flex: 1 },
    { field: 'activity_count', headerName: 'Activiteit Aantal', flex: 1 }
  ]
  return (
    <div style={{ padding: '0 45px 50px 45px' }}>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid rows={rows} columns={columns} pageSize={7} />
      </div>
    </div>
  )
}

type ContentProps = { stats: UsageStat[]; chartType: string }
const Content = ({ stats, chartType }: ContentProps): JSX.Element => {
  if (stats.length === 0) return <></>
  switch (chartType) {
    case 'bar':
      return <UsageChart stats={stats} />
    default:
      return <UsageTable stats={stats} />
  }
}

const ConsultationPriceUsage = (): JSX.Element => {
  const [stats, setStats] = useState<UsageStat[]>([])
  const [psychologist, setPsychologist] = useState<Psychologist | null>(null)
  const [year, setYear] = useState<number>(2024)
  const [chartType, setChartType] = useState('table')
  const { t } = useTranslation('statisticsPage')

  useEffectAsync(async () => {
    const usage = await client.consultationPriceUsage(
      psychologist?.id,
      year || undefined
    )
    setStats(usage.sort((a, b) => b.activity_count - a.activity_count))
  }, [psychologist, year])

  return (
    <Paper style={{ marginBottom: 10 }}>
      <Typography style={{ textAlign: 'center', margin: 20, paddingTop: 20 }}>
        Prijscode gebruik
      </Typography>
      <FormControl style={{ marginLeft: 50 }} component="fieldset">
        <RadioGroup
          row
          aria-label="chart-type"
          name="chart-type"
          value={chartType}
          onChange={(e) => setChartType(e.target.value)}
        >
          <FormControlLabel value="bar" control={<Radio />} label={t('bar')} />
          <FormControlLabel
            value="table"
            control={<Radio />}
            label={t('table')}
          />
        </RadioGroup>
      </FormControl>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr auto',
          paddingLeft: '50px',
          paddingRight: '30px'
        }}
      >
        <PsychologistSelectorControlled
          value={psychologist}
          onChange={setPsychologist}
        />
        <Select
          label="Jaar"
          value={year}
          onChange={(e) => setYear(parseInt(`${e.target.value}`) || 2024)}
        >
          <MenuItem value={2022}>2022</MenuItem>
          <MenuItem value={2023}>2023</MenuItem>
          <MenuItem value={2024}>2024</MenuItem>
        </Select>
      </div>
      <Content chartType={chartType} stats={stats} />
    </Paper>
  )
}

export default ConsultationPriceUsage
