import {
  DateCheckbox,
  Field,
  FileUploadCheckbox,
  RichTextEditor
} from '../../../../react-final-form-wrappers'
import FormRow from '../../FormRow'
import { client } from '../../../../client'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'

const TreatmentPlanSection = ({ name }) => {
  const { t } = useTranslation('treatmentPlanSection')

  const {
    input: { value: programId }
  } = useField('id')

  const handleSubmit = async (file, type) => {
    const response = await client.uploadFile(file, programId, type)
    return response.data
  }

  const handleDelete = async (file) => {
    await client.removeFile(file.id)
  }

  return (
    <>
      <FormRow>
        <Field
          name="treatmentPlan"
          label={t('treatmentPlan')}
          component={RichTextEditor}
        />
      </FormRow>
      <FormRow>
        <Field
          style={{ top: -20, left: 20 }}
          name="treatmentPlanConsentDate"
          label={t('treatmentPlanDate')}
          component={DateCheckbox}
        />
      </FormRow>

      <FormRow>
        <Field
          label={t('startLetter')}
          name="startLetterFile"
          onSubmit={(file) => handleSubmit(file, 'startLetter')}
          onDelete={handleDelete}
          component={FileUploadCheckbox}
        />
      </FormRow>
    </>
  )
}

export default TreatmentPlanSection
