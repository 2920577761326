import { useCallback, useState } from 'react'
import { useField } from 'react-final-form'
import { useTranslation } from 'react-i18next'
import { client } from '../../../../client'
import { CircularProgress, Box } from '@material-ui/core'
import FormRow from '../../FormRow'
import Button from '@material-ui/core/Button'
import * as types from '../../../../types'

type Props = {
  push: (activity: types.Activity2022) => void
}

const NewActivityButton = ({ push }: Props): JSX.Element => {
  const { t } = useTranslation('timeRegistrationSection')

  const [newActivityLoading, setNewActivityLoading] = useState(false)

  const {
    input: { value: primaryPsychologist }
  } = useField('primaryPsychologist')

  const {
    input: { value: programId }
  } = useField('id')

  const primaryPsychologistId = primaryPsychologist && primaryPsychologist.id

  const handleAddActivity = useCallback(async () => {
    setNewActivityLoading(true)
    const activity = await client.addActivity2022(
      programId,
      primaryPsychologistId
    )
    push(activity)
    setNewActivityLoading(false)
  }, [push, primaryPsychologistId, programId])

  return (
    <FormRow>
      <Box>
        {newActivityLoading && (
          <Box width="100%" display="flex" justifyContent="center" padding={2}>
            <CircularProgress />
          </Box>
        )}
        <Button
          fullWidth
          type="button"
          color="primary"
          onClick={handleAddActivity}
        >
          {t('buttonText')}
        </Button>
      </Box>
    </FormRow>
  )
}

export default NewActivityButton
